@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
* {
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff !important;
}

a {
  font-size: 16px;
  color: #575fd4;
  text-decoration: none;
}

button {
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eff1f8;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575fd4;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #575fd4;
}

.BrainhubCarousel__dots {
  align-items: center;
}

.BrainhubCarousel__dot {
  background-color: transparent;
}

.BrainhubCarousel__dot::before {
  background-color: #8ae560 !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
  border-radius: 100% !important;
  transition: 0.1s;
}

.BrainhubCarousel__dot--selected::before {
  background-color: #6b75ff !important;
  width: 15px !important;
  height: 15px !important;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}
.infinite-scroll-component__outerdiv ::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.MuiInput-underline::before {
  border-bottom-style: solid !important;
  border-bottom-color: #c8d5dc !important;
}

.MuiInput-root.Mui-disabled {
  color: #c8d5dc !important;
}

.MuiRadio-root.MuiIconButton-colorPrimary {
  color: #6b75ff;
}
.MuiRadio-root.Mui-checked span div svg:last-child {
  border: 6px solid #6b75ff;
  border-radius: 50px;
}
.MuiRadio-root.Mui-checked span div svg:last-child path {
  display: none;
}
.MuiRadio-root .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.DayPicker-wrapper * {
  outline: 0;
}

.DayPicker-wrapper {
  outline: 0;
  color: #000;
}
.DayPicker-wrapper .DayPicker-NavBar {
  position: absolute;
  width: 100%;
  height: 24px;
  top: 14px;
}
.DayPicker-wrapper .DayPicker-NavButton {
  width: 1.5em;
  height: 1.5em;
  background-size: unset;
}
.DayPicker-wrapper .DayPicker-NavButton--prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M9.317 12l5.442 6.35a1 1 0 1 1-1.518 1.3l-6-7a1 1 0 0 1 0-1.3l6-7a1 1 0 0 1 1.518 1.3L9.317 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 16px;
  margin: 0;
}
.DayPicker-wrapper .DayPicker-NavButton--next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M14.683 12L9.24 5.65a1 1 0 1 1 1.518-1.3l6 7a1 1 0 0 1 0 1.3l-6 7a1 1 0 1 1-1.518-1.3L14.683 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill-rule='nonzero' d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  right: 16px;
  margin: 0;
}
.DayPicker-wrapper .DayPicker-Caption > div {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-Months {
  flex-wrap: nowrap;
}
.DayPicker-wrapper .DayPicker-Weekday {
  color: #bbc2d5;
  line-height: 17px;
  font-size: 12px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-WeekdaysRow .DayPicker-Weekday {
  padding: 0 0 7px 0;
}
.DayPicker-wrapper .DayPicker-WeekdaysRow .DayPicker-Weekday abbr {
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DayPicker-wrapper .DayPicker-Day {
  padding: 11px 12.5px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-Day--start {
  background-color: #6b75ff !important;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--end {
  background-color: #6b75ff !important;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--outside {
  color: #bbc2d5;
}
.DayPicker-wrapper .DayPicker-TodayButton {
  color: #6b75ff;
}
.DayPicker-wrapper .DayPicker-Day--today {
  color: #8ae560;
}
.DayPicker-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #9ea5ff;
}
.DayPicker-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #6b75ff;
}
.DayPicker-wrapper .DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-wrapper .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #ebecff !important;
  border-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--outside {
  background-color: transparent !important;
}
.DayPicker-wrapper .DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--outside {
  background-color: transparent !important;
}

.snackbar {
  min-width: 440px;
  height: 48px;
  border-radius: 3px;
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.snackbar div {
  height: 100% !important;
}
.snackbar .MuiSnackbarContent-message span {
  font-size: 15px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.snackbar .MuiSnackbarContent-message span svg {
  display: none;
}
.snackbar .MuiSnackbarContent-message span::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
  font-family: "icomoon" !important;
  font-size: 15px;
  text-align: center;
  width: 24px;
  margin-right: 12px;
}

.snackbar-success {
  background-color: #2e3a59 !important;
}
.snackbar-success .MuiSnackbarContent-message span::before {
  content: "\e914";
  color: #8ae560;
}
.snackbar-success .MuiSnackbarContent-action span {
  text-transform: uppercase !important;
  color: #8ae560 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.snackbar-error {
  background-color: #ff3d71 !important;
}
.snackbar-error .MuiSnackbarContent-message span::before {
  content: "\e901";
  color: #fff;
  font-size: 20px;
}

@font-face {
  font-family: icomoon;
  src: url("../../assets/fonts/icomoon/icomoon.eot?n1yb46");
  src: url("../../assets/fonts/icomoon/icomoon.eot?n1yb46#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon/icomoon.ttf?n1yb46") format("truetype"), url("../../assets/fonts/icomoon/icomoon.woff?n1yb46") format("woff"), url("../../assets/fonts/icomoon/icomoon.svg?n1yb46#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: graynormalize;
}

.icon-Alert-Filled::before {
  content: "\e900";
  color: #6b75ff;
}

.icon-Alert-Outlined::before {
  content: "\e901";
  color: #6b75ff;
}

.icon-Attachment::before {
  content: "\e902";
  color: #6b75ff;
}

.icon-back::before {
  content: "\e903";
  color: #6b75ff;
}

.icon-Bell-Filled::before {
  content: "\e904";
  color: #6b75ff;
}

.icon-Bell-Outlined::before {
  content: "\e905";
  color: #6b75ff;
}

.icon-Bookmark-Filled::before {
  content: "\e906";
  color: #6b75ff;
}

.icon-Bookmark-Outlined::before {
  content: "\e907";
  color: #6b75ff;
}

.icon-Calendar-Filled::before {
  content: "\e908";
  color: #6b75ff;
}

.icon-Calendar-Outlined::before {
  content: "\e909";
  color: #6b75ff;
}

.icon-Camera-Filled::before {
  content: "\e90a";
  color: #6b75ff;
}

.icon-Camera-Outlined::before {
  content: "\e90b";
  color: #6b75ff;
}

.icon-Cap-Down::before {
  content: "\e90c";
  color: #6b75ff;
}

.icon-Cap-Up::before {
  content: "\e90d";
  color: #6b75ff;
}

.icon-Card-Filled::before {
  content: "\e90e";
  color: #6b75ff;
}

.icon-Card-Outlined::before {
  content: "\e90f";
  color: #6b75ff;
}

.icon-Cart-Filled::before {
  content: "\e910";
  color: #6b75ff;
}

.icon-Cart-Outlined::before {
  content: "\e911";
  color: #6b75ff;
}

.icon-Chart::before {
  content: "\e912";
  color: #6b75ff;
}

.icon-Check-x2::before {
  content: "\e913";
  color: #6b75ff;
}

.icon-Check::before {
  content: "\e914";
  color: #6b75ff;
}

.icon-Chevron-Down::before {
  content: "\e915";
  color: #6b75ff;
}

.icon-Chevron-Left::before {
  content: "\e916";
  color: #6b75ff;
}

.icon-Chevron-Right::before {
  content: "\e917";
  color: #6b75ff;
}

.icon-Chevron-Up::before {
  content: "\e918";
  color: #6b75ff;
}

.icon-Clock-Filled::before {
  content: "\e919";
  color: #6b75ff;
}

.icon-Clock-Outlined::before {
  content: "\e91a";
  color: #6b75ff;
}

.icon-Close::before {
  content: "\e91b";
  color: #6b75ff;
}

.icon-Download::before {
  content: "\e91c";
  color: #6b75ff;
}

.icon-Edit-Filled::before {
  content: "\e91d";
  color: #6b75ff;
}

.icon-Edit-Outlined::before {
  content: "\e91e";
  color: #6b75ff;
}

.icon-Embed::before {
  content: "\e91f";
  color: #6b75ff;
}

.icon-Explore-Filled::before {
  content: "\e920";
  color: #6b75ff;
}

.icon-Explore-Outlined::before {
  content: "\e921";
  color: #6b75ff;
}

.icon-External-link::before {
  content: "\e922";
  color: #6b75ff;
}

.icon-Eye-Filled::before {
  content: "\e923";
  color: #6b75ff;
}

.icon-Eye-Outlined::before {
  content: "\e924";
  color: #6b75ff;
}

.icon-File-Filled::before {
  content: "\e925";
  color: #6b75ff;
}

.icon-File-Outlined::before {
  content: "\e926";
  color: #6b75ff;
}

.icon-Heart-Filled::before {
  content: "\e927";
  color: #6b75ff;
}

.icon-Heart-Outlined::before {
  content: "\e928";
  color: #6b75ff;
}

.icon-Home-Filled::before {
  content: "\e929";
  color: #6b75ff;
}

.icon-Home-Outlined::before {
  content: "\e92a";
  color: #6b75ff;
}

.icon-Image-Filled::before {
  content: "\e92b";
  color: #6b75ff;
}

.icon-Image-Outlined::before {
  content: "\e92c";
  color: #6b75ff;
}

.icon-Info-Filled::before {
  content: "\e92d";
  color: #6b75ff;
}

.icon-Info-Outlined::before {
  content: "\e92e";
  color: #6b75ff;
}

.icon-Link::before {
  content: "\e92f";
  color: #6b75ff;
}

.icon-Lock-Filled::before {
  content: "\e930";
  color: #6b75ff;
}

.icon-Lock-Outlined::before {
  content: "\e931";
  color: #6b75ff;
}

.icon-Mail-Filled::before {
  content: "\e932";
  color: #6b75ff;
}

.icon-Mail-Outlined::before {
  content: "\e933";
  color: #6b75ff;
}

.icon-Menu::before {
  content: "\e934";
  color: #6b75ff;
}

.icon-Message-Filled::before {
  content: "\e935";
  color: #6b75ff;
}

.icon-Mic-Filled::before {
  content: "\e936";
  color: #6b75ff;
}

.icon-Mic-Outlined::before {
  content: "\e937";
  color: #6b75ff;
}

.icon-Minus::before {
  content: "\e938";
  color: #6b75ff;
}

.icon-More::before {
  content: "\e939";
  color: #6b75ff;
}

.icon-Paperplane-Filled::before {
  content: "\e93a";
  color: #6b75ff;
}

.icon-Paperplane-Outlined::before {
  content: "\e93b";
  color: #6b75ff;
}

.icon-Pause-Filled::before {
  content: "\e93c";
  color: #6b75ff;
}

.icon-Pause-Outlined::before {
  content: "\e93d";
  color: #6b75ff;
}

.icon-Person-Filled::before {
  content: "\e93e";
  color: #6b75ff;
}

.icon-Person-Outlined::before {
  content: "\e93f";
  color: #6b75ff;
}

.icon-Phone-Filled::before {
  content: "\e940";
  color: #6b75ff;
}

.icon-Phone-Outlined::before {
  content: "\e941";
  color: #6b75ff;
}

.icon-Play-Filled::before {
  content: "\e942";
  color: #6b75ff;
}

.icon-Play-Outlined::before {
  content: "\e943";
  color: #6b75ff;
}

.icon-Plus::before {
  content: "\e944";
  color: #6b75ff;
}

.icon-Refresh::before {
  content: "\e945";
  color: #6b75ff;
}

.icon-Search::before {
  content: "\e946";
  color: #6b75ff;
}

.icon-Settings-Filled::before {
  content: "\e947";
  color: #6b75ff;
}

.icon-Settings-Outlined::before {
  content: "\e948";
  color: #6b75ff;
}

.icon-Share-Filled::before {
  content: "\e949";
  color: #6b75ff;
}

.icon-Share-Outlined::before {
  content: "\e94a";
  color: #6b75ff;
}

.icon-Star-Filled::before {
  content: "\e94b";
  color: #6b75ff;
}

.icon-Star-Outlined::before {
  content: "\e94c";
  color: #6b75ff;
}

.icon-Trash-Filled::before {
  content: "\e94d";
  color: #6b75ff;
}

.icon-Trash-Outlined::before {
  content: "\e94e";
  color: #6b75ff;
}

.icon-Upload::before {
  content: "\e94f";
  color: #6b75ff;
}

.icon-World::before {
  content: "\e950";
  color: #6b75ff;
}

.icon-menu-invoicing::before {
  content: "\e951";
}

.icon-menu-planning::before {
  content: "\e952";
}

.icon-menu-profile::before {
  content: "\e953";
}

.icon-menu-projects::before {
  content: "\e954";
}

.icon-menu-reports::before {
  content: "\e955";
}

.icon-menu-settings::before {
  content: "\e956";
}

.icon-menu-timeApproval::before {
  content: "\e957";
}

.icon-menu-timeEntry::before {
  content: "\e958";
}

.icon-planning-planningAssistant .path1::before {
  content: "\e959";
  color: #8ae560;
}

.icon-planning-planningAssistant .path2::before {
  content: "\e95a";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-planningAssistant .path3::before {
  content: "\e95b";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-resourcePlanning .path1::before {
  content: "\e95c";
  color: #8ae560;
}

.icon-planning-resourcePlanning .path2::before {
  content: "\e95d";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-resourcePlanning .path3::before {
  content: "\e95e";
  margin-left: -1em;
  color: #6b75ff;
}