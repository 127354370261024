@import 'theme/variables/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.backdrop {
  background-color: rgba(187, 194, 213, 0.63) !important;
}

.paper {
  min-height: unset !important;
  margin: 0;
  top: unset;
}

.modalClose {
  right: 28px;
  top: 28px;
}

.header {
  margin: 25px 0;

  .title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: $semi-black;
    margin: 0;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}

.button {
  height: 40px !important;
}

.disabled {
  opacity: 0.3 !important;
}

.okButton {
  @extend .button;

  padding: 0 32px !important;
}

.cancelButton {
  @extend .button;

  &:hover {
    background-color: white !important;
  }

  padding: 0 !important;
  margin-right: 19px !important;
}

.label {
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px !important;
  letter-spacing: normal;
  text-align: center;
}

.cancelButtonLabel {
  @extend .label;

  font-weight: 600 !important;
  color: $aluminium;
  text-decoration: underline;
}

.okButtonLabel {
  @extend .label;

  font-weight: bold !important;
}
