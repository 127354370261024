$base-width: 1440;
$base-container: 1140px;
$base-inner-width: 1116px;
$base-height: 900;
$radius-low: 3px;
$radius-medium: 10px;
$radius-high: 20px;
$button-height-size: 50px;
$button-border-radius: $radius-low;
$button-font-size: 17px;
$label-font-size: 16px;
$link-font-size: 16px;
$button-font-weight: 600;
$label-font-weight: 600;
$content-min-width: 1120px;
$content-max-width: 1120px;
$left-sidebar-width: 72px;
