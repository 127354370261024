@import 'theme/variables/colors';
@import 'theme/variables/shadows';
@import 'theme/utils/functions';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.backdrop {
  background-color: $backdrop-color !important;
}

.paper {
  width: 384px;
  height: auto;
  min-height: 100%;
  box-shadow: $modal-shadow !important;
  border: none;
  outline: none;
  padding: 28px;
  padding-top: 24px;
  margin-bottom: 105px;
  position: absolute;
  top: 105px;
}

.closeIcon {
  background-color: transparent;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    font-size: 15px;
  }
}
