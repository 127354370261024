@import "theme/variables/colors";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 0;
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: normal;
  text-align: center;
  color: $lavender-grey;
}

.image {
  width: 255px;
  height: 205px;
  object-fit: contain;
}
