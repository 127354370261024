@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/functions';
@import 'theme/utils/mixins';

$header-color: $hawkes-blue;
$tab-width: 128px;
$tab-margin: 20px;

.container {
  width: 100%;
  height: 48px;
  background-color: $header-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.tabs {
  min-height: unset !important;
  height: 100%;

  button {
    margin: 0 $tab-margin;
    padding: 0 !important;
    min-height: unset !important;
    min-width: unset !important;
    max-width: unset !important;
    height: 100%;
  }
}

.tab {
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $black !important;
  text-transform: capitalize !important;
}

.selectedTab {
  font-weight: 600 !important;
}

.tabFlexContainer {
  height: 100%;
}

.tabIndicator {
  height: 4px !important;
  padding: 0 10px;
  margin-left: -10px;
  box-sizing: content-box;
}

.selectedSecondTabIndicator {
  left: 197.984px !important;
  width: 142.062px !important;
}

.selectedFirstTabIndicator {
  left: 20px !important;
  width: 142.062px !important;
}

.selectedSubscriptionTabIndicator {
  left: 20px !important;
  width: 173.406px !important;
}

.selectedInvoicesTabIndicator {
  left: 226.75px !important;
  width: 62.7812px !important;
}

.backButton {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  left: 32px;

  span {
    @include auto-sizing(font-size, 16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
  }

  div {
    @include auto-sizing(
      margin-right,
      (
        576px: 3px,
        1440px: 5px
      )
    );
    @include auto-sizing(font-size, 14px);
    font-weight: normal;
    transition: transform 200ms ease;
  }

  &:hover {
    div {
      transform: translateX(-6px);
    }
  }
}
