@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/mixins';

.root {
  width: 100%;
}

.popover {
  width: 323px;
  height: 350px;
  padding: 23px 25px;
  margin-top: 25px;
}

.selectedItem {
  background-color: $primary-color;
  border-radius: $radius-low;

  span {
    color: $white;
    font-weight: 600;
  }
}

.item {
  height: 38px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid $secondary-color;
  width: 100%;
  padding: 0 10px;

  @include transition(all, 0.2s, ease);

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: $semi-black;
  }

  &:hover {
    background-color: $primary-color;
    border-radius: $radius-low;

    span {
      color: $white;
      font-weight: 600;
    }
  }
}

.input {
  @include ellipsis();

  padding-right: 20px;
}

.tenantOwner {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 5.5px 1.5px 6px;
  border-radius: 2px;
  background-color: #e4e9f2;

  span {
    font-size: 11px;
    font-weight: 600;
    color: $primary-color;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.disabledLabel {
  span {
    color: rgba(0, 0, 0, 0.38);
  }
}

.value {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: $semi-black;
  max-width: 100%;
  display: block;

  @include ellipsis();
}

.email {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  margin-top: 1px;
}

.selectedAsNew {
  width: 100%;
  height: 100%;
  margin: 8px 0 4px 0 !important;
  border-radius: 4px;
  border: solid 1px rgba(138, 229, 96, 0.32) !important;
  background-color: rgba(138, 229, 96, 0.32);
}

.excludeFromSync {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px rgba(255, 61, 113, 0.18) !important;
  background-color: rgba(255, 61, 113, 0.18);
  margin: 4px 0 8px 0 !important;
}

.content {
  width: 100%;
  height: 100%;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  width: 100%;
  height: 47px;
  border-radius: $radius-low;
  display: flex;
  align-items: center;
  background-color: $oyster-bay;
  margin-bottom: 10px;
  padding: 10px;

  input {
    width: calc(100% - 45px);
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;

    &::-webkit-input-placeholder {
      color: $bermuda-grey;
    }

    &:-ms-input-placeholder {
      color: $bermuda-grey;
    }

    &::placeholder {
      color: $bermuda-grey;
    }
  }
}

.searchIcon {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 20px;
  }
}

.clearSearch {
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 10px;

    &::before {
      color: $secondary-color;
    }
  }
}

.list {
  width: 100%;
  overflow: auto;
}

.formControlLabel {
  width: 100%;
  // margin: 0 !important;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $secondary-color;
}

.formControlLabelWithMargin {
  width: 100%;
  margin: 0 !important;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $secondary-color;
}

.selectedFormControlLabel {
  border-bottom-color: transparent;
  background-color: $primary-dark-color;
  border-radius: $radius-low;

  span {
    color: $white !important;
    font-weight: 600 !important;
  }
}

.selectAllFormControl {
  padding-bottom: 5px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.label {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px !important;
  letter-spacing: normal;
  color: $black;
}

.selectAllLabel {
  color: $primary-color;
}

.hidden {
  opacity: 0;
  width: 10px;
  padding: 0;
}
