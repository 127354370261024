@import 'theme/variables/colors';
@import 'theme/utils/mixins';

$drawer-transition-time: 0.5s;
$drawer-small-width: 72px;
$drawer-large-width: 38rem;
$drawer-top-padding: 26px;
$drawer-bottom-padding: 16px;
$icon-width: $drawer-small-width;
$icon-height: 80px;
$icon-font-size: 33px;

.notificationIconContainer {
  background-color: $primary-color;
  width: $drawer-small-width;
  height: 48px;
  z-index: 999;
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  // position: relative;

  .badge {
    position: absolute;
    top: 3px;
    right: 12px;
    background-color: #ff3d71;
    color: white !important;
    border-radius: 12px;
    padding: 0 6px;
    // margin-left: 4px;
    font-size: 12px;
    font-weight: 600;

    &.lessThanTen {
      right: 15px;
    }
  }
}

.NotificationsBar {
  width: $drawer-large-width;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: $white;
  z-index: 999;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: transform 230ms ease-in-out;
  box-shadow: 0 1px 4.5px 0 #bbc2d5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.NotificationsBarOpen {
  transform: translateX(0); /* Move on-screen */
}

.titleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    color: $black;
  }

  .close {
    background-color: $white;
  }
}

.titlesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 12px;

  .dropdownTitle {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: $black;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 3px;
    object-fit: contain;
  }

  .markAsAllRead {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: $primary-dark-color;
    background-color: $white;

    &.disabled {
      opacity: 0.3 !important;
      cursor: default !important;
    }
  }
}

.dropdownRoot {
  width: 100%;
  margin-bottom: 32px;
  border: 1px solid $primary-color;
  padding: 8px 12px;
  height: 51px;
  border-radius: 4px;
  background-color: $white;

  &.mb0 {
    margin-bottom: 0 !important;
  }
}

.dropdownPopover {
  width: 570px;
  height: 250px;
  padding: 24px 32px;
  margin-top: 25px;
}

.dropdownCurrencyPopover {
  width: 570px;
  height: 250px;
  padding: 20px 10px;
  margin-top: 40px;
}

.item {
  height: 45px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid $secondary-color;
  width: 100%;
  padding: 0 10px;

  @include transition(all, 0.2s, ease);

  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: $semi-black;
  }

  &:hover {
    background-color: $primary-color;
    border-radius: $radius-low;
    cursor: pointer;

    span {
      color: $white;
      font-weight: 600;
    }
  }
}

.selectedItem {
  background-color: $primary-color !important;
  border-radius: $radius-low;

  span {
    color: $white !important;
    font-weight: 600 !important;
  }
}

.dropdownInput {
  @include ellipsis();
}

.value {
  @include auto-sizing('font-size', 16px);
  font-weight: 600;
  line-height: 22px;
}

.dropdownInputContainer {
  // padding-bottom: 8px;
  border: none !important;
}

.dropdownPlaceholder {
  line-height: 22px;

  @include auto-sizing('font-size', 16px);
  font-weight: 600;
  color: $black;
}

.tabs {
  width: 100%;
}

.unreadCardContainer {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: #f1f4fc;
  margin: 16px 0 8px 0;
  position: relative;

  .unreadBadge {
    background-color: #ff3d71;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    font-size: 12px;
    position: absolute;
    top: 8px;
    left: 5px;
  }

  .cardTitle {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal;
    color: $primary-dark-color;
    margin: 2px 0 !important;
  }

  .cardDes {
    font-size: 12px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: $black;
    margin: 2px 0 11px 0 !important;
  }

  .cardFooterContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cardDate {
      font-size: 12px !important;
      font-weight: normal;
      line-height: normal;
      color: $black;
    }

    .cardBtn {
      width: 100px;
      height: 30px;
      padding: 3.5px 3px;
      background-color: $primary-color;
      color: $white;
      border-radius: 4px;
      font-size: 12px !important;
      font-weight: 600 !important;
    }
  }
}

.readCardContainer {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background-color: $white;
  border-bottom: 1px solid $lavender-grey;
  margin: 8px 0 8px 0;
  border-radius: 0;

  .cardTitle {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal;
    color: $primary-dark-color;
    margin: 2px 0 !important;
  }

  .cardDes {
    font-size: 12px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: $black;
    margin: 2px 0 11px 0 !important;
  }

  .cardFooterContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cardDate {
      font-size: 12px !important;
      font-weight: normal;
      line-height: normal;
      color: $black;
    }

    .cardBtn {
      width: 100px;
      height: 30px;
      padding: 3.5px 3px;
      background-color: $white;
      border: 1px solid $primary-color;
      color: $primary-color;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
