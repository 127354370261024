@import '../variables/colors';
@import '../variables/sizes';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $white !important;
}

a {
  font-size: $link-font-size;
  color: $link-color;
  text-decoration: none;
}

button {
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
}

//@media (max-width: 992px) {
//  #root {
//    width: max-content;
//  }
//}

// SCROLL-BAR STYLES

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white-lilac;
  border-radius: $radius-low;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-dark-color;
  border-radius: $radius-low;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-dark-color;
}

// React-carousel

.BrainhubCarousel__dots {
  align-items: center;
}

.BrainhubCarousel__dot {
  background-color: transparent;
}

.BrainhubCarousel__dot::before {
  background-color: $secondary-color !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
  border-radius: 100% !important;
  transition: 0.1s;
}

.BrainhubCarousel__dot--selected::before {
  background-color: $primary-color !important;
  width: 15px !important;
  height: 15px !important;
}

// react-router-transition
.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
}

// infinite-scroll-component
.infinite-scroll-component__outerdiv {
  width: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}
