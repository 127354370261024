@import 'theme/variables/colors';
@import 'theme/utils/functions';
@import 'theme/utils/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
}

.head {
  margin-bottom: 14px;
}

.title {
  line-height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: $black;
  margin-bottom: 4px;
  margin-top: 0;
}

.titleBisFlow {
  line-height: normal;
  margin: 15px 0;
  display: flex;

  span {
    font-size: 38px;
    font-weight: 800;
    font-family: 'Heebo-Black', 'Heebo', 'Open Sans', sans-serif;
  }

  .part1 {
    color: $secondary-color;
  }

  .part2 {
    color: $primary-color;
  }

  .part3 {
    color: $black;
    margin-left: 10px;
  }
}

.description {
  margin: 0;
  line-height: 26px;
  font-size: 18px;
  color: $black;
}

.inputCounter {
  padding: 0;
  margin: 0 0 38px 0;

  li {
    font-size: 16px;
    line-height: 2;
    letter-spacing: -0.19px;
    color: $rhino-blue;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .input {
    width: 100px;
    height: 24px;
    background-color: $white;
    text-align: center;
    display: flex;
    position: relative;
    border-radius: 4px;
    margin-right: 12px;
    overflow: hidden;
    user-select: none;

    input {
      width: inherit;
      height: inherit;
      border-radius: 4px;
      border: solid 1px $secondary-color;
      margin: 0;
      padding: 0 20px;
      text-align: center;
      -moz-appearance: textfield;
      font-size: 14px;

      &:focus {
        outline: 0;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .leftMinus,
    .rightPlus {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 24px;
      border-radius: 4px;
      background-color: $secondary-color;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .leftMinus {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rightPlus {
      left: initial;
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.radios {
  margin-bottom: 32px;
}

.radio {
  margin-top: -3px !important;
}

.radioLabelRoot {
  align-items: flex-start !important;
  margin-bottom: 12px;

  &.mb0 {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.radioLabel {
  line-height: 19px !important;
  font-size: 14px !important;
  color: $black !important;
  display: flex !important;
  flex-direction: column !important;
}

.radioName {
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: $rhino-blue;

  span {
    color: $primary-color;
  }
}

.radioDesc {
  line-height: 17px;
  font-size: 12px;
  letter-spacing: -0.14px;
  color: $rhino-blue;
}

.cardWrapper {
  padding-bottom: 38px;
  border-bottom: 1px solid $primary-color;
  margin-bottom: 24px;
  width: 100%;

  .label {
    display: block;
    margin-bottom: 4px;
    line-height: 19px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.17px;
    color: $primary-color;
  }

  .inputWrapper {
    position: relative;
    width: 100%;

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }

  .input {
    width: 100% !important;

    input {
      padding: 14.5px 14px !important;
    }

    fieldset {
      border-color: $mischka;
    }
  }
}
