@import 'theme/variables/colors';

.logo {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Heebo-Black', 'Heebo', 'Open Sans', sans-serif;
    position: absolute;
  }

  .sec1Close {
    color: $secondary-color;
    left: 29%;
    animation: sec1 0.8s;
  }

  .sec2 {
    opacity: 0;
    transition: all 0.8s;
    letter-spacing: -4px;
    left: 30%;
  }

  .sec3Close {
    color: $white;
    left: 53%;
    animation: sec3 0.8s;
  }

  .sec4 {
    opacity: 0;
    left: 40%;
    transition: all 0.8s;
    letter-spacing: -4px;
  }
}

// expand
.logoExpand {
  width: 100%;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Heebo-Black', 'Heebo', 'Open Sans', sans-serif;
    position: absolute;
  }

  .sec1 {
    color: $secondary-color;
    left: 38%;
    transition: all 0.8s;
  }

  .sec2 {
    color: $secondary-color;
    opacity: 1;
    left: 42%;
    transition: all 0.8s 0.3s;
    letter-spacing: normal;
  }

  .sec3 {
    color: $white;
    left: 48%;
    transition: all 0.8s;
  }

  .sec4 {
    color: $white;
    opacity: 1;
    left: 52%;
    transition: all 0.8s 0.3s;
    letter-spacing: normal;
  }
}

@keyframes sec1 {
  from {
    left: 0%;
  }

  to {
    left: 29%;
  }
}

@keyframes sec3 {
  from {
    left: 100%;
  }

  to {
    left: 53%;
  }
}
