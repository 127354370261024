@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/mixins';

.readOnly {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red;
  padding: 6px;
  color: $white;
  // border-radius: 10px;
  width: 100%;
  margin: 0 0 10px 0;

  .textsContainer {
    display: flex;
    width: 57%;
    align-items: center;
    justify-content: center;
  }

  .texts {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .alertIcon {
    margin-right: 25px;

    div {
      font-size: 40px;

      &::before {
        color: $white;
      }
    }
  }
}

.readOnlyHidden {
  display: none;
}

.btn {
  min-width: 15% !important;
  height: 40px !important;
  margin-left: 14px !important;
}

.btnLabel {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.paper {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;

  border-radius: 4px !important;

  max-width: 960px !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
