@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/functions';

.root {
  padding: 0 !important;
  padding-right: 8px !important;
}

.icon {
  width: 19px;
  height: 19px;
  border-radius: $radius-low;
  border: 2px solid $primary-color;
}

.checkedIcon {
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      color: #fff;
      font-size: 11px;
      font-weight: bold;
    }
  }
}
