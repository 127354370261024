@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/functions';
@import 'theme/utils/mixins';

$header-color: $hawkes-blue;
$tab-width: 128px;
$tab-margin: 20px;

.container {
  @include auto-sizing(height, (576px: 36px, 1440px: 52px));
  width: 100%;
  background-color: $header-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 8;
  //transform: translateY(-100%);
  //transition: transform 230ms ease-in-out;

  &.sticky {
    position: sticky;
    top: 0;
  }

  //&.open {
  //  transform: translateY(0);
  //}
}

.backButton {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  left: 32px;

  span {
    @include auto-sizing(font-size, 16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
  }

  div {
    @include auto-sizing(
      margin-right,
      (
        576px: 3px,
        1440px: 5px
      )
    );
    @include auto-sizing(font-size, 14px);
    font-weight: normal;
    transition: transform 200ms ease;
  }

  &:hover {
    div {
      transform: translateX(-6px);
    }
  }
}
