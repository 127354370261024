@import "theme/variables/colors";

.wrapper {
  display: flex;
  flex-direction: column;

  .brand {
    display: flex;
    margin: 50px 0  20px 0;

    * {
      font-size: 38px;
      font-weight: 800;
      font-family: 'Heebo-Black', 'Heebo', 'Open Sans', sans-serif;
    }

    .bis {
      color: $secondary-color;
    }

    .flow {
      color: $primary-color;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;

    * {
      font-size: 16px;
    }

    .head {
      font-size: 30px;
    }
  }

  .paidDate {
    display: flex;
    flex-direction: column;

    .paidTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      * {
        font-size: 16px;
        font-weight: 600;
        color: $label-color;
      }
    }

    .paidInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;

      * {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .details {
    padding: 26px 24px;
    background-color: $hawkes-blue-secondary;

    .firstLine {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $link-water2;
      padding-bottom: 16px;

      .firstLineHead {
        font-size: 16px;
        font-weight: bold;
      }

      .fInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .members {
          color: $label-color;
        }

        * {
          font-size: 16px;
        }
      }
    }

    .secondLine {
      display: flex;
      flex-direction: column;
      padding-top: 16px;

      .sInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infoTitle {
          font-size: 16px;
          font-weight: bold;
        }

        .amount {
          font-weight: bold;
          font-size: 19px;
        }
      }

      .calc {
        font-size: 15px;
        align-self: flex-end;
      }
    }
  }

  .contact {
    margin: 15px 0 30px 0;
    display: flex;
    flex-direction: column;

    .download {
      font-weight: bold;
      font-size: 16px;
      color: $primary-dark-color;
      text-decoration: underline;
      margin-bottom: 16px;
    }

    .contactDetails {
      span {
        color: $primary-dark-color;
      }
    }
  }

  .btns {
    padding-top: 68px;
    padding-bottom: 50px;
    border-top: 1px solid $link-water2;
    display: flex;

    * {
      height: 55px;
    }

    .skip {
      width: 40%;
    }

    .continue {
      width: 60%;
      margin-left: 16px;
      display: flex;
      align-items: center;

      svg {
        g {
          use {
            fill: $white;
          }
        }
      }
    }
  }
}

.loading {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
