@import 'theme/variables/colors';

body {
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  min-height: 100vh;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingContainer {
  width: 30%;
  min-width: 17em;
  margin-top: 3em;
}

.loadingBarColorPrimary {
  background-color: $secondary-color !important;
}

.loadingColorPrimary {
  background-color: white !important;
}
