$primary-light-color: #6f96e1;
$primary-color: #6b75ff;
$primary-dark-color: #575fd4;
$secondary-light-color: #3dd3b5;
$secondary-color: #8ae560;
$secondary-dark-color: #10b3b4;
$error-color: #ee6f7d;
$red: #ff3d71;
$purple: #8761cb;
$bostonBlue: #3996b3;
$white: #fff;
$black: #000;
$semi-black: #131926;
$label-color: #98a7ad;
$link-color: $primary-dark-color;
$backdrop-color: rgba(255, 255, 255, 0.63);
$hawkes-blue: #e4e9f2;
$hawkes-blue-secondary: #eff1f8;
$bossanova: #4b4350;
$lavender-grey: #bbc2d5;
$rhino-blue: #2e3a59;
$heather-blue: #bbc2d5;
$athens-gray: #e1e4eb;
$aluminium: #aaaeb8;
$te-papa-green: #1f323a;
$mischka: #d4d8e2;
$white-lilac: #eff1f8;
$oyster-bay: #d6e6ec;
$bermuda-grey: #7493a0;
$link-water: #d1dde3;
$link-water2: #c8d5dc;
$manatee: #9498a2;
$gainsboro: #ddd;
$athens-gray2: #eef0f5;
$athens-gray3: #e5e8f0a6;
$biloba-flower: #b1b5eb;
$fog: #d7daff;
