@import "theme/variables/colors";
@import "theme/utils/functions";
@import "theme/utils/mixins";

.cardWrapper {
  margin-bottom: 48px;
  position: relative;
  width: 100%;
}

.label {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: $primary-color;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.stripeIcon {
  position: absolute;
  right: 2px;
  top: -9px;
}

.input {
  width: 100% !important;

  input {
    padding: 14.5px 14px !important;
  }

  fieldset {
    border-color: $mischka;
  }
}

.dropdown > button {
  height: 3rem;
  border-bottom: none;
}

.dropdown {
  border: 1px solid #d4d8e2;
  padding: 0 16px;
  border-radius: 4px;
}

.inputContainer {
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    right: 12px;
    width: 5em;
  }
}

.submit {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  margin: 0;
  border-top: 1px solid $primary-color;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .calcBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .total {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.24px;
    color: $rhino-blue;
    margin: 0;

    .num {
      font-weight: bold;
    }

    .type {
      font-size: 14px;
      letter-spacing: -0.17px;
    }
  }

  .calc {
    font-size: 15px;
    line-height: 32px;
    letter-spacing: -0.18px;
    color: $rhino-blue;
    margin-bottom: 0;
    margin-top: -6px;
  }

  .btn {
    min-width: 48% !important;
    height: 55px !important;
  }

  .cancel {
    margin: 0 22px 0 0;
  }

  .btnLabel {
    font-size: 20px !important;
    line-height: 27px !important;
    font-weight: bold !important;
    letter-spacing: -0.24px !important;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancelButton {
  @include auto-sizing('font-size', 18px);
  line-height: 40px;
  font-weight: 600;
  color: $aluminium;
  padding: 0 12px;
  margin-right: 12px;
  cursor: pointer;
  text-decoration: underline;
  user-select: none;
  outline: 0;
  background-color: transparent;
}

.mainButton {
  @include auto-sizing('padding-right', (576px: 10px, 1440px: 16px), true);
  @include auto-sizing('padding-left', (576px: 10px, 1440px: 16px), true);
  height: 40px !important;
}
