@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/utils/mixins';

.failedSyncStatusBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red;
  padding: 16px 6px;
  color: $white;
  // border-radius: 10px;
  width: 100%;
  // margin: 0 0 10px 0;

  .syncStatusContainer {
    display: flex;
    width: 67%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    min-width: 980px;

    &.narrowWidth {
      width: 70%;
    }
  }

  .textsContainer {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .title {
    font-size: 17px !important;
    line-height: 25px !important;
    font-weight: 600 !important;
    color: $white;
  }

  .des {
    font-size: 16px !important;
    line-height: 25px !important;
    font-weight: normal !important;
    color: $white;
    margin: 0 !important;
  }
}

.warningStatusBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffaa01;
  padding: 16px 6px;
  color: $white;
  // border-radius: 10px;
  width: 100%;
  // margin: 0 0 10px 0;

  .syncStatusContainer {
    display: flex;
    width: 67%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    min-width: 980px;
    &.narrowWidth {
      width: 70%;
    }
  }

  .hideWarningStatusBar {
    display: none;
  }

  .textsContainer {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .title {
    font-size: 17px !important;
    line-height: 25px !important;
    font-weight: 600 !important;
    color: $white;
  }

  .des {
    font-size: 16px !important;
    line-height: normal !important;
    font-weight: normal !important;
    color: $white;
    margin: 0 !important;
  }
}

.syncStatusBarHidden {
  display: none;
}

.btn {
  min-width: 15% !important;
  background: transparent;
  text-decoration: underline;
  height: 40px !important;
  margin-left: 14px !important;
}

.btnLabel {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: normal !important;
  letter-spacing: -0.24px !important;
  background-color: transparent;
  text-decoration: underline;
  color: $white;
}

.reviewBtnsContainer {
  display: flex;
  align-items: center;
}

.reviewUpdatesBtn {
  min-width: 15% !important;
  background-color: $white !important;
  height: 40px !important;
  margin-left: 10px !important;
  border-radius: 4px;
}

.reviewUpdatesBtnLabel {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  letter-spacing: -0.24px !important;
  color: #ffaa01;
}
