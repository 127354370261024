@import 'theme/variables/colors';
@import 'theme/variables/shadows';
@import 'theme/variables/sizes';
@import 'theme/utils/functions';
@import 'theme/utils/mixins';

$placeholder-color: $oyster-bay;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  &.disabled {
    user-select: none;
  }
}

.titleContainer {
  margin-bottom: 4.5px;
}

.title {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: $black;

  &.required::after {
    content: '*';
    color: $red;
    font-weight: 600;
  }
}

.disabledTitle {
  color: $aluminium;
}

.inputContainer {
  position: relative;
  border-bottom: 1px solid $primary-color;
  width: 100%;
  padding: 0;
  padding-bottom: 3.5px;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.disabledInputContainer {
  border-bottom-color: $athens-gray;
  cursor: not-allowed;
}

.icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin-right: 12px;

  & > div {
    font-size: 10px;

    &::before {
      color: $secondary-color;
    }
  }
}

.placeholder {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: $black;
}

.popover {
  box-shadow: $dropdown-shadow !important;
  border-radius: $radius-low !important;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  width: 100%;
  height: 47px;
  border-radius: $radius-low;
  display: flex;
  align-items: center;
  background-color: $oyster-bay;
  margin-bottom: 10px;
  padding: 10px;

  input {
    width: calc(100% - 45px);
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;

    &::-webkit-input-placeholder {
      color: $bermuda-grey;
    }

    &:-ms-input-placeholder {
      color: $bermuda-grey;
    }

    &::placeholder {
      color: $bermuda-grey;
    }
  }
}

.searchIcon {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 20px;
  }
}

.clearSearch {
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 10px;

    &::before {
      color: $secondary-color;
    }
  }
}

.selectedItem {
  background-color: $primary-color !important;
  border-radius: $radius-low;

  span {
    color: $white !important;
    font-weight: 600 !important;
  }
}

.item {
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  border-bottom: 1px solid $secondary-color;
  width: 100%;
  padding: 2px 10px;

  @include transition(all, 0.2s, ease);

  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: $semi-black;
  }

  &:hover {
    background-color: $primary-color;
    border-radius: $radius-low;

    span {
      color: $white;
      font-weight: 600;
    }
  }

  .email {
    @include auto-sizing('font-size', 13px);
    font-weight: normal;
    line-height: normal;
  }
}
