@import 'theme/variables/colors';
@import 'theme/variables/sizes';
@import 'theme/variables/shadows';
@import 'theme/utils/mixins';
@import 'theme/utils/functions';

.container {
  height: 40px;
}

.tabs {
  width: 100% !important;
  height: 100% !important;
  min-height: unset !important;
  background-color: $white !important;
  // border-radius: $radius-high;
  overflow: visible !important;
  border-bottom: 1px solid $lavender-grey;
}

.tabsIndicator {
  height: 36px !important;
  background-color: $white !important;
  // border: 2px solid $white !important;
  box-shadow: none !important;
  // border-radius: $radius-high;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: none !important;
}

.indicatorText {
  color: $primary-color !important;
  font-weight: bold !important;
  font-size: 14px;
}

.tabsFlexContainer {
  justify-content: flex-start;
  align-items: center;
}

.tabsFixedContainer {
  overflow: visible !important;
}

.tab {
  min-height: unset !important;
  min-width: unset !important;
  width: 100px;
  padding: 0 !important;
  // flex: 1;
  height: 40px !important;
  opacity: 1 !important;
  // border-radius: $radius-high !important;

  span {
    text-transform: capitalize !important;
    color: $semi-black !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .tabLabel {
    display: flex;
    align-items: center;
  }

  .badge {
    background-color: #ff3d71;
    color: white !important;
    border-radius: 11px;
    padding: 2px 7px;
    margin-left: 4px;
    font-size: 12px;
    display: inline-block;
  }
}

.disabledTab {
  opacity: 0.5 !important;
}

.tabLabel {
  display: flex;
  align-items: center;
}

.badge {
  background-color: #ff3d71;
  color: white;
  border-radius: 11px;
  padding: 2px 7px;
  margin-left: 4px;
  font-size: 12px;
  display: inline-block;
}
