@import "../variables/colors";
@import "../variables/sizes";

.MuiInput-underline::before {
  border-bottom-style: solid !important;
  border-bottom-color: $link-water2 !important;
}

.MuiInput-root.Mui-disabled {
  color: $link-water2 !important;
}

.MuiRadio-root {
  &.MuiIconButton-colorPrimary {
    color: $primary-color;
  }

  &.Mui-checked span div svg:last-child {
    border: 6px solid $primary-color;
    border-radius: 50px;

    path {
      display: none;
    }
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
}

// Start - React Day Picker
.DayPicker-wrapper * {
  outline: 0;
}

.DayPicker-wrapper {
  outline: 0;
  color: $black;

  .DayPicker-NavBar {
    position: absolute;
    width: 100%;
    height: 24px;
    top: 14px;
  }

  .DayPicker-NavButton {
    width: 1.5em;
    height: 1.5em;
    background-size: unset;
  }

  .DayPicker-NavButton--prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M9.317 12l5.442 6.35a1 1 0 1 1-1.518 1.3l-6-7a1 1 0 0 1 0-1.3l6-7a1 1 0 0 1 1.518 1.3L9.317 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    top: 0;
    left: 16px;
    margin: 0;
  }

  .DayPicker-NavButton--next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M14.683 12L9.24 5.65a1 1 0 1 1 1.518-1.3l6 7a1 1 0 0 1 0 1.3l-6 7a1 1 0 1 1-1.518-1.3L14.683 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill-rule='nonzero' d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    top: 0;
    right: 16px;
    margin: 0;
  }

  .DayPicker-Caption > div {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .DayPicker-Months {
    flex-wrap: nowrap;
  }

  .DayPicker-Weekday {
    color: $heather-blue;
    line-height: 17px;
    font-size: 12px;
    font-weight: 600;
  }

  .DayPicker-WeekdaysRow {
    .DayPicker-Weekday {
      padding: 0 0 7px 0;

      abbr {
        padding: 0.5em;
        border-bottom: 1px solid $gainsboro;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .DayPicker-Day {
    padding: 11px 12.5px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 600;
  }

  .DayPicker-Day--start {
    background-color: $primary-color !important;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .DayPicker-Day--end {
    background-color: $primary-color !important;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .DayPicker-Day--outside {
    color: $heather-blue;
  }

  .DayPicker-TodayButton {
    color: $primary-color;
  }

  .DayPicker-Day--today {
    color: $secondary-color;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: lighten($primary-color, 10%);
    //color: lighten($primary-color, 15%);
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $primary-color;
  }

  .DayPicker:not(.DayPicker--interactionDisabled),
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: lighten($primary-color, 25%) !important;
    border-radius: 100px;
  }

  .DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: transparent !important;
  }

  .DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: transparent !important;
  }
}
// End - React Day Picker

.snackbar {
  min-width: 440px;
  height: 48px;
  border-radius: $radius-low;
  padding-right: 16px !important;
  padding-left: 16px !important;

  div {
    height: 100% !important;
  }

  .MuiSnackbarContent-message {
    span {
      font-size: 15px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;

      svg {
        display: none;
      }

      &::before {
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
        font-family: 'icomoon' !important;
        font-size: 15px;
        text-align: center;
        width: 24px;
        margin-right: 12px;
      }
    }
  }
}

.snackbar-success {
  background-color: $rhino-blue !important;

  .MuiSnackbarContent-message {
    span {
      &::before {
        content: "\e914";
        color: $secondary-color;
      }
    }
  }

  .MuiSnackbarContent-action {
    span {
      text-transform: uppercase !important;
      color: $secondary-color !important;
      font-size: 15px !important;
      font-weight: 600 !important;
    }
  }
}

.snackbar-error {
  background-color: $red !important;

  .MuiSnackbarContent-message {
    span {
      &::before {
        content: "\e901";
        color: $white;
        font-size: 20px;
      }
    }
  }
}
