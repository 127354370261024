@import "../variables/colors";

$icomoon-font-path: '../../assets/fonts/icomoon';

@font-face {
  font-family: icomoon;
  src: url('#{$icomoon-font-path}/icomoon.eot?n1yb46');
  src:
    url('#{$icomoon-font-path}/icomoon.eot?n1yb46#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?n1yb46') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?n1yb46') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?n1yb46#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: graynormalize;
}

.icon-Alert-Filled::before {
  content: "\e900";
  color: $primary-color;
}

.icon-Alert-Outlined::before {
  content: "\e901";
  color: $primary-color;
}

.icon-Attachment::before {
  content: "\e902";
  color: $primary-color;
}

.icon-back::before {
  content: "\e903";
  color: $primary-color;
}

.icon-Bell-Filled::before {
  content: "\e904";
  color: $primary-color;
}

.icon-Bell-Outlined::before {
  content: "\e905";
  color: $primary-color;
}

.icon-Bookmark-Filled::before {
  content: "\e906";
  color: $primary-color;
}

.icon-Bookmark-Outlined::before {
  content: "\e907";
  color: $primary-color;
}

.icon-Calendar-Filled::before {
  content: "\e908";
  color: $primary-color;
}

.icon-Calendar-Outlined::before {
  content: "\e909";
  color: $primary-color;
}

.icon-Camera-Filled::before {
  content: "\e90a";
  color: $primary-color;
}

.icon-Camera-Outlined::before {
  content: "\e90b";
  color: $primary-color;
}

.icon-Cap-Down::before {
  content: "\e90c";
  color: $primary-color;
}

.icon-Cap-Up::before {
  content: "\e90d";
  color: $primary-color;
}

.icon-Card-Filled::before {
  content: "\e90e";
  color: $primary-color;
}

.icon-Card-Outlined::before {
  content: "\e90f";
  color: $primary-color;
}

.icon-Cart-Filled::before {
  content: "\e910";
  color: $primary-color;
}

.icon-Cart-Outlined::before {
  content: "\e911";
  color: $primary-color;
}

.icon-Chart::before {
  content: "\e912";
  color: $primary-color;
}

.icon-Check-x2::before {
  content: "\e913";
  color: $primary-color;
}

.icon-Check::before {
  content: "\e914";
  color: $primary-color;
}

.icon-Chevron-Down::before {
  content: "\e915";
  color: $primary-color;
}

.icon-Chevron-Left::before {
  content: "\e916";
  color: $primary-color;
}

.icon-Chevron-Right::before {
  content: "\e917";
  color: $primary-color;
}

.icon-Chevron-Up::before {
  content: "\e918";
  color: $primary-color;
}

.icon-Clock-Filled::before {
  content: "\e919";
  color: $primary-color;
}

.icon-Clock-Outlined::before {
  content: "\e91a";
  color: $primary-color;
}

.icon-Close::before {
  content: "\e91b";
  color: $primary-color;
}

.icon-Download::before {
  content: "\e91c";
  color: $primary-color;
}

.icon-Edit-Filled::before {
  content: "\e91d";
  color: $primary-color;
}

.icon-Edit-Outlined::before {
  content: "\e91e";
  color: $primary-color;
}

.icon-Embed::before {
  content: "\e91f";
  color: $primary-color;
}

.icon-Explore-Filled::before {
  content: "\e920";
  color: $primary-color;
}

.icon-Explore-Outlined::before {
  content: "\e921";
  color: $primary-color;
}

.icon-External-link::before {
  content: "\e922";
  color: $primary-color;
}

.icon-Eye-Filled::before {
  content: "\e923";
  color: $primary-color;
}

.icon-Eye-Outlined::before {
  content: "\e924";
  color: $primary-color;
}

.icon-File-Filled::before {
  content: "\e925";
  color: $primary-color;
}

.icon-File-Outlined::before {
  content: "\e926";
  color: $primary-color;
}

.icon-Heart-Filled::before {
  content: "\e927";
  color: $primary-color;
}

.icon-Heart-Outlined::before {
  content: "\e928";
  color: $primary-color;
}

.icon-Home-Filled::before {
  content: "\e929";
  color: $primary-color;
}

.icon-Home-Outlined::before {
  content: "\e92a";
  color: $primary-color;
}

.icon-Image-Filled::before {
  content: "\e92b";
  color: $primary-color;
}

.icon-Image-Outlined::before {
  content: "\e92c";
  color: $primary-color;
}

.icon-Info-Filled::before {
  content: "\e92d";
  color: $primary-color;
}

.icon-Info-Outlined::before {
  content: "\e92e";
  color: $primary-color;
}

.icon-Link::before {
  content: "\e92f";
  color: $primary-color;
}

.icon-Lock-Filled::before {
  content: "\e930";
  color: $primary-color;
}

.icon-Lock-Outlined::before {
  content: "\e931";
  color: $primary-color;
}

.icon-Mail-Filled::before {
  content: "\e932";
  color: $primary-color;
}

.icon-Mail-Outlined::before {
  content: "\e933";
  color: $primary-color;
}

.icon-Menu::before {
  content: "\e934";
  color: $primary-color;
}

.icon-Message-Filled::before {
  content: "\e935";
  color: $primary-color;
}

.icon-Mic-Filled::before {
  content: "\e936";
  color: $primary-color;
}

.icon-Mic-Outlined::before {
  content: "\e937";
  color: $primary-color;
}

.icon-Minus::before {
  content: "\e938";
  color: $primary-color;
}

.icon-More::before {
  content: "\e939";
  color: $primary-color;
}

.icon-Paperplane-Filled::before {
  content: "\e93a";
  color: $primary-color;
}

.icon-Paperplane-Outlined::before {
  content: "\e93b";
  color: $primary-color;
}

.icon-Pause-Filled::before {
  content: "\e93c";
  color: $primary-color;
}

.icon-Pause-Outlined::before {
  content: "\e93d";
  color: $primary-color;
}

.icon-Person-Filled::before {
  content: "\e93e";
  color: $primary-color;
}

.icon-Person-Outlined::before {
  content: "\e93f";
  color: $primary-color;
}

.icon-Phone-Filled::before {
  content: "\e940";
  color: $primary-color;
}

.icon-Phone-Outlined::before {
  content: "\e941";
  color: $primary-color;
}

.icon-Play-Filled::before {
  content: "\e942";
  color: $primary-color;
}

.icon-Play-Outlined::before {
  content: "\e943";
  color: $primary-color;
}

.icon-Plus::before {
  content: "\e944";
  color: $primary-color;
}

.icon-Refresh::before {
  content: "\e945";
  color: $primary-color;
}

.icon-Search::before {
  content: "\e946";
  color: $primary-color;
}

.icon-Settings-Filled::before {
  content: "\e947";
  color: $primary-color;
}

.icon-Settings-Outlined::before {
  content: "\e948";
  color: $primary-color;
}

.icon-Share-Filled::before {
  content: "\e949";
  color: $primary-color;
}

.icon-Share-Outlined::before {
  content: "\e94a";
  color: $primary-color;
}

.icon-Star-Filled::before {
  content: "\e94b";
  color: $primary-color;
}

.icon-Star-Outlined::before {
  content: "\e94c";
  color: $primary-color;
}

.icon-Trash-Filled::before {
  content: "\e94d";
  color: $primary-color;
}

.icon-Trash-Outlined::before {
  content: "\e94e";
  color: $primary-color;
}

.icon-Upload::before {
  content: "\e94f";
  color: $primary-color;
}

.icon-World::before {
  content: "\e950";
  color: $primary-color;
}

.icon-menu-invoicing::before {
  content: "\e951";
}

.icon-menu-planning::before {
  content: "\e952";
}

.icon-menu-profile::before {
  content: "\e953";
}

.icon-menu-projects::before {
  content: "\e954";
}

.icon-menu-reports::before {
  content: "\e955";
}

.icon-menu-settings::before {
  content: "\e956";
}

.icon-menu-timeApproval::before {
  content: "\e957";
}

.icon-menu-timeEntry::before {
  content: "\e958";
}

.icon-planning-planningAssistant .path1::before {
  content: "\e959";
  color: $secondary-color;
}

.icon-planning-planningAssistant .path2::before {
  content: "\e95a";
  margin-left: -1em;
  color: $primary-color;
}

.icon-planning-planningAssistant .path3::before {
  content: "\e95b";
  margin-left: -1em;
  color: $primary-color;
}

.icon-planning-resourcePlanning .path1::before {
  content: "\e95c";
  color: $secondary-color;
}

.icon-planning-resourcePlanning .path2::before {
  content: "\e95d";
  margin-left: -1em;
  color: $primary-color;
}

.icon-planning-resourcePlanning .path3::before {
  content: "\e95e";
  margin-left: -1em;
  color: $primary-color;
}
