@import './functions';

$breakpoints: (
  'desktop': 1024px
) !default;

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin box($width: 'auto', $height: 'auto', $color: '') {
  width: $width;
  height: $height;

  @if ($color != '') {
    background-color: $color;
  }
}

@mixin transition($prop, $duration, $func) {
  -webkit-transition: $prop $duration $func;
  -moz-transition: $prop $duration $func;
  -o-transition: $prop $duration $func;
  transition: $prop $duration $func;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/// auto-sizing
/// Generate linear interpolated size values through multiple break points
/// @param $property - A string CSS property name
/// @param $map - A Sass map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include auto-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@mixin auto-sizing($property, $map, $important: false) {
  // Check font-size property for automatic create map
  @if ($property == 'font-size') {
    $map: font-size-map($map);
  }

  // Check property is important
  @if $important {
    $important: '!important';
  }

  @else {
    $important: '';
  }

  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error "auto-sizing() $map requires at least values";
  }

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys, 1)) #{$important};

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width: nth($keys, $i)) {
      $value1: map-get($map, nth($keys, $i));
      $value2: map-get($map, nth($keys, ($i + 1)));
      // If values are not equal, perform linear interpolation
      @if ($value1 != $value2) {
        #{$property}:
          linear-interpolation(
            (
              nth($keys, $i): $value1,
              nth($keys, ($i + 1)): $value2
            )
          )
          #{$important};
      }

      @else {
        #{$property}: $value1 #{$important};
      }
    }
  }

  // Maximum size
  @media (min-width: nth($keys, $length)) {
    #{$property}: map-get($map, nth($keys, $length)) #{$important};
  }
}
