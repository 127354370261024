@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);


.Modal_modal__1vwov {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.Modal_backdrop__1mf1y {
  background-color: rgba(255, 255, 255, 0.63) !important;
}

.Modal_paper__2ATRB {
  width: 384px;
  height: auto;
  min-height: 100%;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.22) !important;
  border: none;
  outline: none;
  padding: 28px;
  padding-top: 24px;
  margin-bottom: 105px;
  position: absolute;
  top: 105px;
  overflow: auto;
}

.Modal_closeIcon__1IP48 {
  background-color: transparent;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Modal_closeIcon__1IP48 div {
  font-size: 15px;
}
.ModalConfirm_container__cwq23 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.ModalConfirm_backdrop__NSBLp {
  background-color: rgba(187, 194, 213, 0.63) !important;
}

.ModalConfirm_paper__38Flt {
  min-height: unset !important;
  margin: 0;
  top: unset;
}

.ModalConfirm_modalClose__1z6Py {
  right: 28px;
  top: 28px;
}

.ModalConfirm_header__AAyBV {
  margin: 25px 0;
}
.ModalConfirm_header__AAyBV .ModalConfirm_title__NVofM {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #131926;
  margin: 0;
}

.ModalConfirm_footer__3tlwC {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}

.ModalConfirm_button__3rbHY, .ModalConfirm_cancelButton__36tBL, .ModalConfirm_okButton__C_jKp {
  height: 40px !important;
}

.ModalConfirm_disabled__1I4Lx {
  opacity: 0.3 !important;
}

.ModalConfirm_okButton__C_jKp {
  padding: 0 32px !important;
}

.ModalConfirm_cancelButton__36tBL {
  padding: 0 !important;
  margin-right: 19px !important;
}
.ModalConfirm_cancelButton__36tBL:hover {
  background-color: white !important;
}

.ModalConfirm_label__TrM9O, .ModalConfirm_okButtonLabel__2y_ec, .ModalConfirm_cancelButtonLabel__3YyPn {
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px !important;
  letter-spacing: normal;
  text-align: center;
}

.ModalConfirm_cancelButtonLabel__3YyPn {
  font-weight: 600 !important;
  color: #aaaeb8;
  text-decoration: underline;
}

.ModalConfirm_okButtonLabel__2y_ec {
  font-weight: bold !important;
}
.MenuLogo_logo__224B1 {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MenuLogo_logo__224B1 span {
  font-family: "Heebo-Black", "Heebo", "Open Sans", sans-serif;
  position: absolute;
}
.MenuLogo_logo__224B1 .MenuLogo_sec1Close__M3jRR {
  color: #8ae560;
  left: 29%;
  -webkit-animation: MenuLogo_sec1__2RorX 0.8s;
          animation: MenuLogo_sec1__2RorX 0.8s;
}
.MenuLogo_logo__224B1 .MenuLogo_sec2__3bEXo {
  opacity: 0;
  transition: all 0.8s;
  letter-spacing: -4px;
  left: 30%;
}
.MenuLogo_logo__224B1 .MenuLogo_sec3Close__mqwo_ {
  color: #fff;
  left: 53%;
  -webkit-animation: MenuLogo_sec3__1V3mL 0.8s;
          animation: MenuLogo_sec3__1V3mL 0.8s;
}
.MenuLogo_logo__224B1 .MenuLogo_sec4__27Wrp {
  opacity: 0;
  left: 40%;
  transition: all 0.8s;
  letter-spacing: -4px;
}

.MenuLogo_logoExpand__1PuBG {
  width: 100%;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MenuLogo_logoExpand__1PuBG span {
  font-family: "Heebo-Black", "Heebo", "Open Sans", sans-serif;
  position: absolute;
}
.MenuLogo_logoExpand__1PuBG .MenuLogo_sec1__2RorX {
  color: #8ae560;
  left: 38%;
  transition: all 0.8s;
}
.MenuLogo_logoExpand__1PuBG .MenuLogo_sec2__3bEXo {
  color: #8ae560;
  opacity: 1;
  left: 42%;
  transition: all 0.8s 0.3s;
  letter-spacing: normal;
}
.MenuLogo_logoExpand__1PuBG .MenuLogo_sec3__1V3mL {
  color: #fff;
  left: 48%;
  transition: all 0.8s;
}
.MenuLogo_logoExpand__1PuBG .MenuLogo_sec4__27Wrp {
  color: #fff;
  opacity: 1;
  left: 52%;
  transition: all 0.8s 0.3s;
  letter-spacing: normal;
}

@-webkit-keyframes MenuLogo_sec1__2RorX {
  from {
    left: 0%;
  }
  to {
    left: 29%;
  }
}

@keyframes MenuLogo_sec1__2RorX {
  from {
    left: 0%;
  }
  to {
    left: 29%;
  }
}
@-webkit-keyframes MenuLogo_sec3__1V3mL {
  from {
    left: 100%;
  }
  to {
    left: 53%;
  }
}
@keyframes MenuLogo_sec3__1V3mL {
  from {
    left: 100%;
  }
  to {
    left: 53%;
  }
}
.Dropdown_root__3d0gt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.Dropdown_root__3d0gt.Dropdown_disabled__rGR-T {
  -webkit-user-select: none;
          user-select: none;
}

.Dropdown_titleContainer__3fp7G {
  margin-bottom: 4.5px;
}

.Dropdown_title__t91hv {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #000;
}
.Dropdown_title__t91hv.Dropdown_required__2Nsvy::after {
  content: "*";
  color: #ff3d71;
  font-weight: 600;
}

.Dropdown_disabledTitle__OuNGO {
  color: #aaaeb8;
}

.Dropdown_inputContainer__3tXSW {
  position: relative;
  border-bottom: 1px solid #6b75ff;
  width: 100%;
  padding: 0;
  padding-bottom: 3.5px;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Dropdown_disabledInputContainer__1-LLp {
  border-bottom-color: #e1e4eb;
  cursor: not-allowed;
}

.Dropdown_icon__2EcAY {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin-right: 12px;
}
.Dropdown_icon__2EcAY > div {
  font-size: 10px;
}
.Dropdown_icon__2EcAY > div::before {
  color: #8ae560;
}

.Dropdown_placeholder__26Ko3 {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #000;
}

.Dropdown_popover__89wrx {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.29) !important;
  border-radius: 3px !important;
}

.Dropdown_loading__1o7BM {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dropdown_searchBox__XMfvj {
  width: 100%;
  height: 47px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #d6e6ec;
  margin-bottom: 10px;
  padding: 10px;
}
.Dropdown_searchBox__XMfvj input {
  width: calc(100% - 45px);
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
}
.Dropdown_searchBox__XMfvj input::-webkit-input-placeholder {
  color: #7493a0;
}
.Dropdown_searchBox__XMfvj input::placeholder {
  color: #7493a0;
}

.Dropdown_searchIcon__5D0b8 {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Dropdown_searchIcon__5D0b8 div {
  font-size: 20px;
}

.Dropdown_clearSearch__1dVg- {
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Dropdown_clearSearch__1dVg- div {
  font-size: 10px;
}
.Dropdown_clearSearch__1dVg- div::before {
  color: #8ae560;
}

.Dropdown_selectedItem__HzzXF {
  background-color: #6b75ff !important;
  border-radius: 3px;
}
.Dropdown_selectedItem__HzzXF span {
  color: #fff !important;
  font-weight: 600 !important;
}

.Dropdown_item__2a-4w {
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  border-bottom: 1px solid #8ae560;
  width: 100%;
  padding: 2px 10px;
  transition: all 0.2s ease;
}
.Dropdown_item__2a-4w span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #131926;
}
.Dropdown_item__2a-4w:hover {
  background-color: #6b75ff;
  border-radius: 3px;
}
.Dropdown_item__2a-4w:hover span {
  color: #fff;
  font-weight: 600;
}
.Dropdown_item__2a-4w .Dropdown_email__zKit0 {
  font-size: 11px;
  font-weight: normal;
  line-height: normal;
}
@media (min-width: 767px) {
  .Dropdown_item__2a-4w .Dropdown_email__zKit0 {
    font-size: calc(0.5361930295vw + 6.8873994638px);
  }
}
@media (min-width: 1140px) {
  .Dropdown_item__2a-4w .Dropdown_email__zKit0 {
    font-size: 13px;
  }
}
.Checkbox_root__1Iguj {
  padding: 0 !important;
  padding-right: 8px !important;
}

.Checkbox_icon__2yxLt {
  width: 19px;
  height: 19px;
  border-radius: 3px;
  border: 2px solid #6b75ff;
}

.Checkbox_checkedIcon__mQIn2 {
  background-color: #6b75ff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Checkbox_checkedIcon__mQIn2 > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Checkbox_checkedIcon__mQIn2 > div::before {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
.DropdownSearch_root__2kMlw {
  width: 100%;
}

.DropdownSearch_popover__33Ljh {
  width: 323px;
  height: 350px;
  padding: 23px 25px;
  margin-top: 25px;
}

.DropdownSearch_selectedItem__1K1D5 {
  background-color: #6b75ff;
  border-radius: 3px;
}
.DropdownSearch_selectedItem__1K1D5 span {
  color: #fff;
  font-weight: 600;
}

.DropdownSearch_item__2SAI7 {
  height: 38px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid #8ae560;
  width: 100%;
  padding: 0 10px;
  transition: all 0.2s ease;
}
.DropdownSearch_item__2SAI7 span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #131926;
}
.DropdownSearch_item__2SAI7:hover {
  background-color: #6b75ff;
  border-radius: 3px;
}
.DropdownSearch_item__2SAI7:hover span {
  color: #fff;
  font-weight: 600;
}

.DropdownSearch_input__L5hsc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
}

.DropdownSearch_tenantOwner__bj-0E {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 5.5px 1.5px 6px;
  border-radius: 2px;
  background-color: #e4e9f2;
}
.DropdownSearch_tenantOwner__bj-0E span {
  font-size: 11px;
  font-weight: 600;
  color: #6b75ff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.DropdownSearch_disabledLabel__2fvwW span {
  color: rgba(0, 0, 0, 0.38);
}

.DropdownSearch_value__25NYb {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #131926;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DropdownSearch_email__2v_Z6 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-top: 1px;
}

.DropdownSearch_selectedAsNew__1Fphs {
  width: 100%;
  height: 100%;
  margin: 8px 0 4px 0 !important;
  border-radius: 4px;
  border: solid 1px rgba(138, 229, 96, 0.32) !important;
  background-color: rgba(138, 229, 96, 0.32);
}

.DropdownSearch_excludeFromSync__1kAGD {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px rgba(255, 61, 113, 0.18) !important;
  background-color: rgba(255, 61, 113, 0.18);
  margin: 4px 0 8px 0 !important;
}

.DropdownSearch_content__qPOgA {
  width: 100%;
  height: 100%;
}

.DropdownSearch_loading__1RHMS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DropdownSearch_searchBox__Jmykn {
  width: 100%;
  height: 47px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #d6e6ec;
  margin-bottom: 10px;
  padding: 10px;
}
.DropdownSearch_searchBox__Jmykn input {
  width: calc(100% - 45px);
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
}
.DropdownSearch_searchBox__Jmykn input::-webkit-input-placeholder {
  color: #7493a0;
}
.DropdownSearch_searchBox__Jmykn input::placeholder {
  color: #7493a0;
}

.DropdownSearch_searchIcon__3XhQr {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DropdownSearch_searchIcon__3XhQr div {
  font-size: 20px;
}

.DropdownSearch_clearSearch__1YIK3 {
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DropdownSearch_clearSearch__1YIK3 div {
  font-size: 10px;
}
.DropdownSearch_clearSearch__1YIK3 div::before {
  color: #8ae560;
}

.DropdownSearch_list__3zIiu {
  width: 100%;
  overflow: auto;
}

.DropdownSearch_formControlLabel__3NOLj {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #8ae560;
}

.DropdownSearch_formControlLabelWithMargin__o4OWW {
  width: 100%;
  margin: 0 !important;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #8ae560;
}

.DropdownSearch_selectedFormControlLabel__2WqvY {
  border-bottom-color: transparent;
  background-color: #575fd4;
  border-radius: 3px;
}
.DropdownSearch_selectedFormControlLabel__2WqvY span {
  color: #fff !important;
  font-weight: 600 !important;
}

.DropdownSearch_selectAllFormControl__2zl4h {
  padding-bottom: 5px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.DropdownSearch_label__1EFhq {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px !important;
  letter-spacing: normal;
  color: #000;
}

.DropdownSearch_selectAllLabel__af8QS {
  color: #6b75ff;
}

.DropdownSearch_hidden__3-UUd {
  opacity: 0;
  width: 10px;
  padding: 0;
}
.PaymentForm_cardWrapper__2wwVw {
  margin-bottom: 48px;
  position: relative;
  width: 100%;
}

.PaymentForm_label__29nje {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
}

.PaymentForm_inputWrapper__3Vg0L {
  position: relative;
  width: 100%;
}

.PaymentForm_stripeIcon__1PCA0 {
  position: absolute;
  right: 2px;
  top: -9px;
}

.PaymentForm_input__3tbfQ {
  width: 100% !important;
}
.PaymentForm_input__3tbfQ input {
  padding: 14.5px 14px !important;
}
.PaymentForm_input__3tbfQ fieldset {
  border-color: #d4d8e2;
}

.PaymentForm_dropdown__2ysNI > button {
  height: 3rem;
  border-bottom: none;
}

.PaymentForm_dropdown__2ysNI {
  border: 1px solid #d4d8e2;
  padding: 0 16px;
  border-radius: 4px;
}

.PaymentForm_inputContainer__2PoN4 {
  display: flex;
  align-items: center;
}
.PaymentForm_inputContainer__2PoN4 svg {
  position: absolute;
  right: 12px;
  width: 5em;
}

.PaymentForm_submit__3vszK {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  margin: 0;
  border-top: 1px solid #6b75ff;
}
@media (max-width: 768px) {
  .PaymentForm_submit__3vszK {
    flex-direction: column;
    align-items: flex-start;
  }
}
.PaymentForm_submit__3vszK .PaymentForm_calcBox__FgSkd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.PaymentForm_submit__3vszK .PaymentForm_total__1h2_r {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  color: #2e3a59;
  margin: 0;
}
.PaymentForm_submit__3vszK .PaymentForm_total__1h2_r .PaymentForm_num__2BbcH {
  font-weight: bold;
}
.PaymentForm_submit__3vszK .PaymentForm_total__1h2_r .PaymentForm_type__3prOl {
  font-size: 14px;
  letter-spacing: -0.17px;
}
.PaymentForm_submit__3vszK .PaymentForm_calc__3J2uc {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 0;
  margin-top: -6px;
}
.PaymentForm_submit__3vszK .PaymentForm_btn__3TgH9 {
  min-width: 48% !important;
  height: 55px !important;
}
.PaymentForm_submit__3vszK .PaymentForm_cancel__3xWpk {
  margin: 0 22px 0 0;
}
.PaymentForm_submit__3vszK .PaymentForm_btnLabel__1mXwy {
  font-size: 20px !important;
  line-height: 27px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.PaymentForm_actions__jtXZ1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.PaymentForm_cancelButton__2ekLE {
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  color: #aaaeb8;
  padding: 0 12px;
  margin-right: 12px;
  cursor: pointer;
  text-decoration: underline;
  -webkit-user-select: none;
          user-select: none;
  outline: 0;
  background-color: transparent;
}
@media (min-width: 767px) {
  .PaymentForm_cancelButton__2ekLE {
    font-size: calc(0.5361930295vw + 11.8873994638px);
  }
}
@media (min-width: 1140px) {
  .PaymentForm_cancelButton__2ekLE {
    font-size: 18px;
  }
}

.PaymentForm_mainButton__q1lNl {
  padding-right: 10px !important;
  padding-left: 10px !important;
  height: 40px !important;
}
@media (min-width: 576px) {
  .PaymentForm_mainButton__q1lNl {
    padding-right: calc(0.6944444444vw + 6px) !important;
  }
}
@media (min-width: 1440px) {
  .PaymentForm_mainButton__q1lNl {
    padding-right: 16px !important;
  }
}
@media (min-width: 576px) {
  .PaymentForm_mainButton__q1lNl {
    padding-left: calc(0.6944444444vw + 6px) !important;
  }
}
@media (min-width: 1440px) {
  .PaymentForm_mainButton__q1lNl {
    padding-left: 16px !important;
  }
}
.UpdateSubscription_wrapper__YeJfm {
  display: flex;
  flex-direction: column;
  padding: 32px 100px;
  position: relative;
}

.UpdateSubscription_backBtn__qEEej {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -50px;
  margin-bottom: 20px;
  padding: 0;
}
.UpdateSubscription_backBtn__qEEej:hover {
  background-color: transparent;
}

.UpdateSubscription_backBtnLabel__3EgFb {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575fd4;
}

.UpdateSubscription_head__1SsMT {
  margin-bottom: 24px;
}

.UpdateSubscription_title__1l0_A {
  line-height: 41px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
  margin-top: 0;
}

.UpdateSubscription_inputContainer__3NDLB {
  margin-bottom: 37px;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_label__2AIka {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
  min-height: 19px;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_inputWrapper__e_as5 {
  position: relative;
  width: 100%;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_inputWrapper__e_as5 > svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 12px;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_input__2k3uo {
  width: 100% !important;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_input__2k3uo input {
  padding: 14.5px 14px !important;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_input__2k3uo fieldset {
  border-color: #d4d8e2;
}
.UpdateSubscription_inputContainer__3NDLB .UpdateSubscription_inputError__1A_ak {
  margin-top: 12px;
  color: #ff3d71;
  line-height: 20px;
  height: 40px;
}

.UpdateSubscription_boxes__1Mzdb {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.UpdateSubscription_boxes__1Mzdb .UpdateSubscription_arrowIcon__1vZ8W {
  font-size: 30px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: flex;
  align-items: center;
  padding-right: 34px;
  padding-left: 34px;
}

.UpdateSubscription_box__2hpoH {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.UpdateSubscription_box__2hpoH.UpdateSubscription_disable__31jXm {
  opacity: 0.5;
}
.UpdateSubscription_box__2hpoH .UpdateSubscription_title__1l0_A {
  color: #2e3a59;
  margin-bottom: 13px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.UpdateSubscription_box__2hpoH .UpdateSubscription_inner__1Fq-N {
  padding: 30px 26px;
  border-radius: 4px;
  background-color: #eff1f8;
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: #2e3a59;
  min-height: 280px;
  height: 270px;
}

.UpdateSubscription_boxDetails__ulleZ {
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 45px;
  display: block;
}
.UpdateSubscription_boxDetails__ulleZ span {
  color: #6b75ff;
}

.UpdateSubscription_innerTitle__2NfaR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.UpdateSubscription_innerTitle__2NfaR .UpdateSubscription_title__1l0_A {
  line-height: 33px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.29px;
  color: #2e3a59;
}

.UpdateSubscription_radios__3Zs2f {
  margin-bottom: 32px;
}

.UpdateSubscription_radio__2Iw2a {
  margin-top: -3px !important;
}

.UpdateSubscription_radioLabelRoot__3gNVF {
  align-items: flex-start !important;
  margin-bottom: 12px;
}
.UpdateSubscription_radioLabelRoot__3gNVF.UpdateSubscription_mb0__1R0GQ {
  margin-bottom: 0;
}
.UpdateSubscription_radioLabelRoot__3gNVF:last-child {
  margin-bottom: 0 !important;
}

.UpdateSubscription_radioLabel__1qwgG {
  line-height: 19px !important;
  font-size: 14px !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
}

.UpdateSubscription_radioName__3-lqb {
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: #2e3a59;
}

.UpdateSubscription_isDisable__3yyLD {
  opacity: 0.65;
  position: relative;
}
.UpdateSubscription_isDisable__3yyLD::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.UpdateSubscription_radioDesc__19-0R {
  line-height: 17px;
  font-size: 12px;
  letter-spacing: -0.14px;
  color: #2e3a59;
}

.UpdateSubscription_cardWrapper__1tcQe {
  padding-bottom: 38px;
  border-bottom: 1px solid #6b75ff;
  margin-bottom: 24px;
  width: 100%;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_label__2AIka {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_inputWrapper__e_as5 {
  position: relative;
  width: 100%;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_inputWrapper__e_as5 > svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 12px;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_input__2k3uo {
  width: 100% !important;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_input__2k3uo input {
  padding: 14.5px 14px !important;
}
.UpdateSubscription_cardWrapper__1tcQe .UpdateSubscription_input__2k3uo fieldset {
  border-color: #d4d8e2;
}

.UpdateSubscription_submit__DiGsd {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 24px;
  margin: 0;
  border-top: 1px solid #6b75ff;
  position: relative;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .UpdateSubscription_submit__DiGsd {
    flex-direction: column;
    align-items: flex-start;
  }
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_calcBox__gOjIs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_total__cp38U {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #2e3a59;
  margin: 0;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_total__cp38U .UpdateSubscription_num__Q4iPQ {
  font-weight: bold;
  margin: 0 12px;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_total__cp38U .UpdateSubscription_type__1uLHO {
  font-size: 14px;
  letter-spacing: -0.17px;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_calc__yYlkp {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 0;
  margin-top: -6px;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_btn__1mFuC {
  min-width: 167px !important;
  height: 55px !important;
}
.UpdateSubscription_submit__DiGsd .UpdateSubscription_btnLabel__3OYvC {
  font-size: 20px !important;
  line-height: 27px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.UpdateSubscription_paper__8PuVX {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;
}

.UpdateSubscription_modalWarning__tigMS {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  max-height: 35vh;
}
.UpdateSubscription_modalWarning__tigMS .UpdateSubscription_text__9hL7M {
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.18px;
  margin: 0 0 45px 0;
}
.UpdateSubscription_modalWarning__tigMS .UpdateSubscription_text__9hL7M strong {
  margin: 0 10px;
}
.UpdateSubscription_modalWarning__tigMS .UpdateSubscription_btnsModal__2vvxQ {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.UpdateSubscription_modalWarning__tigMS .UpdateSubscription_btnsModal__2vvxQ .UpdateSubscription_cancelBtn__7ZofZ {
  text-decoration: underline;
  color: #aaaeb8;
}
.UpdateSubscription_modalWarning__tigMS .UpdateSubscription_btnsModal__2vvxQ .UpdateSubscription_submitBtnModal__3Clpm {
  width: 30%;
  margin: 0 12px;
}

.UpdateSubscription_loading__39kBn {
  height: 100%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100;
}

.UpdateSubscription_errorText__3utx2 {
  color: #ff3d71;
  font-size: 18px;
  line-height: 1.75rem;
}

.UpdateSubscription_cancel__Ukmmu {
  font-size: 14px;
  font-weight: 600;
  width: 104px;
  height: 55px !important;
  margin: 0;
  margin-right: 20px !important;
}

.UpdateSubscription_cancelBtnLabel__3F-xy {
  font-size: 17px !important;
  line-height: 20px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
  color: #aaaeb8;
  text-decoration: underline;
}
.Modal_modal__3C1Fk {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.Modal_backdrop__1rK53 {
  background-color: rgba(255, 255, 255, 0.63) !important;
}

.Modal_paper__1BmwW {
  width: 384px;
  height: auto;
  min-height: 100%;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.22) !important;
  border: none;
  outline: none;
  padding: 28px;
  padding-top: 24px;
  margin-bottom: 105px;
  position: absolute;
  top: 105px;
}

.Modal_closeIcon__1baLV {
  background-color: transparent;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Modal_closeIcon__1baLV div {
  font-size: 15px;
}
.PaymentForm_cardWrapper__3PNwM {
  margin-bottom: 48px;
  position: relative;
  width: 100%;
}

.PaymentForm_label__2N4yX {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
}

.PaymentForm_inputWrapper__2FIaU {
  position: relative;
  width: 100%;
}

.PaymentForm_stripeIcon__2ZynT {
  position: absolute;
  right: 2px;
  top: -9px;
}

.PaymentForm_input__2bKVL {
  width: 100% !important;
}
.PaymentForm_input__2bKVL input {
  padding: 14.5px 14px !important;
}
.PaymentForm_input__2bKVL fieldset {
  border-color: #d4d8e2;
}

.PaymentForm_dropdown__1kxEu > button {
  height: 3rem;
  border-bottom: none;
}

.PaymentForm_dropdown__1kxEu {
  border: 1px solid #d4d8e2;
  padding: 0 16px;
  border-radius: 4px;
}

.PaymentForm_inputContainer__33anE {
  display: flex;
  align-items: center;
}
.PaymentForm_inputContainer__33anE svg {
  position: absolute;
  right: 12px;
  width: 5em;
}

.PaymentForm_submit__2X0yl {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  margin: 0;
  border-top: 1px solid #6b75ff;
}
@media (max-width: 768px) {
  .PaymentForm_submit__2X0yl {
    flex-direction: column;
    align-items: flex-start;
  }
}
.PaymentForm_submit__2X0yl .PaymentForm_calcBox__2Cu5V {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.PaymentForm_submit__2X0yl .PaymentForm_total__1hFLr {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  color: #2e3a59;
  margin: 0;
}
.PaymentForm_submit__2X0yl .PaymentForm_total__1hFLr .PaymentForm_num__boVMn {
  font-weight: bold;
}
.PaymentForm_submit__2X0yl .PaymentForm_total__1hFLr .PaymentForm_type__2-xyV {
  font-size: 14px;
  letter-spacing: -0.17px;
}
.PaymentForm_submit__2X0yl .PaymentForm_calc__2W5zb {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 0;
  margin-top: -6px;
}
.PaymentForm_submit__2X0yl .PaymentForm_btn__120UH {
  min-width: 48% !important;
  height: 55px !important;
}
.PaymentForm_submit__2X0yl .PaymentForm_cancel__pIPQy {
  margin: 0 22px 0 0;
}
.PaymentForm_submit__2X0yl .PaymentForm_btnLabel__2iM3Y {
  font-size: 20px !important;
  line-height: 27px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.PaymentForm_actions__1pwtT {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.PaymentForm_cancelButton__38en5 {
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  color: #aaaeb8;
  padding: 0 12px;
  margin-right: 12px;
  cursor: pointer;
  text-decoration: underline;
  -webkit-user-select: none;
          user-select: none;
  outline: 0;
  background-color: transparent;
}
@media (min-width: 767px) {
  .PaymentForm_cancelButton__38en5 {
    font-size: calc(0.5361930295vw + 11.8873994638px);
  }
}
@media (min-width: 1140px) {
  .PaymentForm_cancelButton__38en5 {
    font-size: 18px;
  }
}

.PaymentForm_mainButton__wZGOY {
  padding-right: 10px !important;
  padding-left: 10px !important;
  height: 40px !important;
}
@media (min-width: 576px) {
  .PaymentForm_mainButton__wZGOY {
    padding-right: calc(0.6944444444vw + 6px) !important;
  }
}
@media (min-width: 1440px) {
  .PaymentForm_mainButton__wZGOY {
    padding-right: 16px !important;
  }
}
@media (min-width: 576px) {
  .PaymentForm_mainButton__wZGOY {
    padding-left: calc(0.6944444444vw + 6px) !important;
  }
}
@media (min-width: 1440px) {
  .PaymentForm_mainButton__wZGOY {
    padding-left: 16px !important;
  }
}
.PaymentInvoice_wrapper__3cf_I {
  display: flex;
  flex-direction: column;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_brand__C4QMW {
  display: flex;
  margin: 50px 0 20px 0;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_brand__C4QMW * {
  font-size: 38px;
  font-weight: 800;
  font-family: "Heebo-Black", "Heebo", "Open Sans", sans-serif;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_brand__C4QMW .PaymentInvoice_bis__1W75t {
  color: #8ae560;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_brand__C4QMW .PaymentInvoice_flow__skfy2 {
  color: #6b75ff;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_title__2CAo3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_title__2CAo3 * {
  font-size: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_title__2CAo3 .PaymentInvoice_head__2hzgx {
  font-size: 30px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_paidDate__1tloq {
  display: flex;
  flex-direction: column;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_paidDate__1tloq .PaymentInvoice_paidTitle__FtQAf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_paidDate__1tloq .PaymentInvoice_paidTitle__FtQAf * {
  font-size: 16px;
  font-weight: 600;
  color: #98a7ad;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_paidDate__1tloq .PaymentInvoice_paidInfo__3YPO8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_paidDate__1tloq .PaymentInvoice_paidInfo__3YPO8 * {
  font-weight: 600;
  font-size: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 {
  padding: 26px 24px;
  background-color: #eff1f8;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_firstLine__2StIe {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c8d5dc;
  padding-bottom: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_firstLine__2StIe .PaymentInvoice_firstLineHead__K1Krk {
  font-size: 16px;
  font-weight: bold;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_firstLine__2StIe .PaymentInvoice_fInfo__Tz8Wd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_firstLine__2StIe .PaymentInvoice_fInfo__Tz8Wd .PaymentInvoice_members__2zvGo {
  color: #98a7ad;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_firstLine__2StIe .PaymentInvoice_fInfo__Tz8Wd * {
  font-size: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_secondLine__ZaNxE {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_secondLine__ZaNxE .PaymentInvoice_sInfo__2yUHa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_secondLine__ZaNxE .PaymentInvoice_sInfo__2yUHa .PaymentInvoice_infoTitle__X6XQl {
  font-size: 16px;
  font-weight: bold;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_secondLine__ZaNxE .PaymentInvoice_sInfo__2yUHa .PaymentInvoice_amount__1CBGq {
  font-weight: bold;
  font-size: 19px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_details__2C1j1 .PaymentInvoice_secondLine__ZaNxE .PaymentInvoice_calc__hQAmk {
  font-size: 15px;
  align-self: flex-end;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_contact__3Z2Gv {
  margin: 15px 0 30px 0;
  display: flex;
  flex-direction: column;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_contact__3Z2Gv .PaymentInvoice_download__69pG3 {
  font-weight: bold;
  font-size: 16px;
  color: #575fd4;
  text-decoration: underline;
  margin-bottom: 16px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_contact__3Z2Gv .PaymentInvoice_contactDetails__1ohE3 span {
  color: #575fd4;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_btns__38Yoh {
  padding-top: 68px;
  padding-bottom: 50px;
  border-top: 1px solid #c8d5dc;
  display: flex;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_btns__38Yoh * {
  height: 55px;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_btns__38Yoh .PaymentInvoice_skip__OXCFH {
  width: 40%;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_btns__38Yoh .PaymentInvoice_continue__snnhz {
  width: 60%;
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.PaymentInvoice_wrapper__3cf_I .PaymentInvoice_btns__38Yoh .PaymentInvoice_continue__snnhz svg g use {
  fill: #fff;
}

.PaymentInvoice_loading__tZ-B9 {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.PaymentPlan_wrapper__XFYyW {
  display: flex;
  flex-direction: column;
}

.PaymentPlan_head__1Qeoo {
  margin-bottom: 14px;
}

.PaymentPlan_title__2NuLE {
  line-height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
  margin-top: 0;
}

.PaymentPlan_titleBisFlow__3AY33 {
  line-height: normal;
  margin: 15px 0;
  display: flex;
}
.PaymentPlan_titleBisFlow__3AY33 span {
  font-size: 38px;
  font-weight: 800;
  font-family: "Heebo-Black", "Heebo", "Open Sans", sans-serif;
}
.PaymentPlan_titleBisFlow__3AY33 .PaymentPlan_part1__3Z00M {
  color: #8ae560;
}
.PaymentPlan_titleBisFlow__3AY33 .PaymentPlan_part2__2VNaF {
  color: #6b75ff;
}
.PaymentPlan_titleBisFlow__3AY33 .PaymentPlan_part3___0NnM {
  color: #000;
  margin-left: 10px;
}

.PaymentPlan_description__3kPEt {
  margin: 0;
  line-height: 26px;
  font-size: 18px;
  color: #000;
}

.PaymentPlan_inputCounter__3fhcd {
  padding: 0;
  margin: 0 0 38px 0;
}
.PaymentPlan_inputCounter__3fhcd li {
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.19px;
  color: #2e3a59;
  display: flex;
  width: 100%;
  align-items: center;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex {
  width: 100px;
  height: 24px;
  background-color: #fff;
  text-align: center;
  display: flex;
  position: relative;
  border-radius: 4px;
  margin-right: 12px;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex input {
  width: inherit;
  height: inherit;
  border-radius: 4px;
  border: solid 1px #8ae560;
  margin: 0;
  padding: 0 20px;
  text-align: center;
  -moz-appearance: textfield;
  font-size: 14px;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex input:focus {
  outline: 0;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex input::-webkit-outer-spin-button, .PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex .PaymentPlan_leftMinus__x4TJ4,
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex .PaymentPlan_rightPlus__2Hq5M {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 24px;
  border-radius: 4px;
  background-color: #8ae560;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex .PaymentPlan_leftMinus__x4TJ4 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.PaymentPlan_inputCounter__3fhcd .PaymentPlan_input__2pyex .PaymentPlan_rightPlus__2Hq5M {
  left: initial;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.PaymentPlan_radios__3_xRQ {
  margin-bottom: 32px;
}

.PaymentPlan_radio__3RKJx {
  margin-top: -3px !important;
}

.PaymentPlan_radioLabelRoot__eUG5E {
  align-items: flex-start !important;
  margin-bottom: 12px;
}
.PaymentPlan_radioLabelRoot__eUG5E.PaymentPlan_mb0__1JXU_ {
  margin-bottom: 0;
}
.PaymentPlan_radioLabelRoot__eUG5E:last-child {
  margin-bottom: 0 !important;
}

.PaymentPlan_radioLabel__PGwuO {
  line-height: 19px !important;
  font-size: 14px !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
}

.PaymentPlan_radioName__Ql-b5 {
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: #2e3a59;
}
.PaymentPlan_radioName__Ql-b5 span {
  color: #6b75ff;
}

.PaymentPlan_radioDesc__12dvX {
  line-height: 17px;
  font-size: 12px;
  letter-spacing: -0.14px;
  color: #2e3a59;
}

.PaymentPlan_cardWrapper__ZMWg6 {
  padding-bottom: 38px;
  border-bottom: 1px solid #6b75ff;
  margin-bottom: 24px;
  width: 100%;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_label__3tPf_ {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_inputWrapper__3PTE2 {
  position: relative;
  width: 100%;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_inputWrapper__3PTE2 > svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 12px;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_input__2pyex {
  width: 100% !important;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_input__2pyex input {
  padding: 14.5px 14px !important;
}
.PaymentPlan_cardWrapper__ZMWg6 .PaymentPlan_input__2pyex fieldset {
  border-color: #d4d8e2;
}
.SideBar_container__2bltl {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #6b75ff;
  width: 72px;
  z-index: 999;
  padding: 26px 0 16px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-transform: translateX(-78px) scale(1.15);
          transform: translateX(-78px) scale(1.15);
  transition: width 0.5s ease-in-out, -webkit-transform 230ms ease-in-out;
  transition: width 0.5s ease-in-out, transform 230ms ease-in-out;
  transition: width 0.5s ease-in-out, transform 230ms ease-in-out, -webkit-transform 230ms ease-in-out;
}
.SideBar_container__2bltl::-webkit-scrollbar {
  display: none;
}
.SideBar_container__2bltl:hover {
  width: 275px;
}
.SideBar_container__2bltl:hover .SideBar_listItemText__jhVgo {
  -webkit-transform: translate3d(0, 0, 0) !important;
          transform: translate3d(0, 0, 0) !important;
  opacity: 1 !important;
}
.SideBar_container__2bltl:hover .SideBar_logoContainer__2XLE- .SideBar_logo__1F1aP {
  width: 100%;
  height: 48px;
  border-radius: 48px;
}
.SideBar_container__2bltl:hover .SideBar_logoContainer__2XLE- .SideBar_logo__1F1aP svg {
  width: 100px;
}
.SideBar_container__2bltl:hover .SideBar_listItem__1uYVJ.SideBar_active__3FLmJ::before {
  width: 93%;
}
.SideBar_container__2bltl.SideBar_open__3zxZh {
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
}

.SideBar_logoContainer__2XLE- {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px 26px 18px;
}
.SideBar_logoContainer__2XLE- .SideBar_logo__1F1aP {
  width: 48px;
  background-color: #575fd4;
  border-radius: 48px;
  transition: width 0.5s ease-in-out, border-radius 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SideBar_listItem__1uYVJ {
  padding: 0 !important;
  position: relative !important;
  height: 60px;
  margin: 15px 0 !important;
  transition: background-color 300ms ease-in-out !important;
  background-color: transparent !important;
}
.SideBar_listItem__1uYVJ:first-child {
  margin-top: 0 !important;
}
.SideBar_listItem__1uYVJ::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: width 300ms;
}
.SideBar_listItem__1uYVJ:not(.SideBar_active__3FLmJ):hover::before {
  width: 90%;
  background-color: #575fd4 !important;
}
.SideBar_listItem__1uYVJ.SideBar_active__3FLmJ::before {
  width: 90%;
  background-color: #8ae560;
}
.SideBar_listItem__1uYVJ.SideBar_active__3FLmJ .SideBar_listItemText__jhVgo {
  color: #575fd4;
}

.SideBar_listItemText__jhVgo {
  min-width: 125px !important;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: translate3d(-25px, 0, 0) !important;
          transform: translate3d(-25px, 0, 0) !important;
  transition: all 300ms linear;
}
.SideBar_listItemText__jhVgo span {
  font-size: 17px;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.SideBar_icon__3u5-E {
  width: 72px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.SideBar_icon__3u5-E div {
  object-fit: contain;
  color: #fff;
  font-size: 33px;
}

.SideBar_userItems__1BTMo {
  margin-top: auto !important;
}

.SideBar_mini__2lw3O.SideBar_container__2bltl {
  height: 96vh;
  top: 2vh;
  padding: 16px 0 0 0;
}
.SideBar_mini__2lw3O .SideBar_logoContainer__2XLE- {
  padding: 0 18px 8px 18px;
}
.SideBar_mini__2lw3O .SideBar_listItem__1uYVJ {
  height: 54px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.SideBar_paper__113NC {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;
  border-radius: 4px !important;
  max-width: 960px !important;
}

.SideBar_modal__30JRk {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.Tabs_container__l5mn- {
  height: 40px;
}

.Tabs_tabs__mNPqW {
  width: 100% !important;
  height: 100% !important;
  min-height: unset !important;
  background-color: #fff !important;
  overflow: visible !important;
  border-bottom: 1px solid #bbc2d5;
}

.Tabs_tabsIndicator__3v89a {
  height: 36px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tabs_indicatorText__3EiGZ {
  color: #6b75ff !important;
  font-weight: bold !important;
  font-size: 14px;
}

.Tabs_tabsFlexContainer__2TMUw {
  justify-content: flex-start;
  align-items: center;
}

.Tabs_tabsFixedContainer__1xCmS {
  overflow: visible !important;
}

.Tabs_tab__28Mkm {
  min-height: unset !important;
  min-width: unset !important;
  width: 100px;
  padding: 0 !important;
  height: 40px !important;
  opacity: 1 !important;
}
.Tabs_tab__28Mkm span {
  text-transform: capitalize !important;
  color: #131926 !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
.Tabs_tab__28Mkm .Tabs_tabLabel__xuPtU {
  display: flex;
  align-items: center;
}
.Tabs_tab__28Mkm .Tabs_badge__2pWso {
  background-color: #ff3d71;
  color: white !important;
  border-radius: 11px;
  padding: 2px 7px;
  margin-left: 4px;
  font-size: 12px;
  display: inline-block;
}

.Tabs_disabledTab__1MOaJ {
  opacity: 0.5 !important;
}

.Tabs_tabLabel__xuPtU {
  display: flex;
  align-items: center;
}

.Tabs_badge__2pWso {
  background-color: #ff3d71;
  color: white;
  border-radius: 11px;
  padding: 2px 7px;
  margin-left: 4px;
  font-size: 12px;
  display: inline-block;
}
.Notifications_notificationIconContainer__1CIfb {
  background-color: #6b75ff;
  width: 72px;
  height: 48px;
  z-index: 999;
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
}
.Notifications_notificationIconContainer__1CIfb .Notifications_badge__1mIh2 {
  position: absolute;
  top: 3px;
  right: 12px;
  background-color: #ff3d71;
  color: white !important;
  border-radius: 12px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 600;
}
.Notifications_notificationIconContainer__1CIfb .Notifications_badge__1mIh2.Notifications_lessThanTen__1GPXr {
  right: 15px;
}

.Notifications_NotificationsBar__S--FI {
  width: 38rem;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 230ms ease-in-out;
  transition: transform 230ms ease-in-out;
  transition: transform 230ms ease-in-out, -webkit-transform 230ms ease-in-out;
  box-shadow: 0 1px 4.5px 0 #bbc2d5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.Notifications_NotificationsBar__S--FI::-webkit-scrollbar {
  display: none;
}

.Notifications_NotificationsBarOpen__2FPQB {
  -webkit-transform: translateX(0);
          transform: translateX(0); /* Move on-screen */
}

.Notifications_titleContainer__3DDu1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Notifications_titleContainer__3DDu1 .Notifications_title__1212J {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  color: #000;
}
.Notifications_titleContainer__3DDu1 .Notifications_close__1hSZ3 {
  background-color: #fff;
}

.Notifications_titlesContainer__1qwpH {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 12px;
}
.Notifications_titlesContainer__1qwpH .Notifications_dropdownTitle__1lgJS {
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: #000;
}
.Notifications_titlesContainer__1qwpH .Notifications_icon__2PIS8 {
  width: 24px;
  height: 24px;
  margin-right: 3px;
  object-fit: contain;
}
.Notifications_titlesContainer__1qwpH .Notifications_markAsAllRead__2PxTj {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #575fd4;
  background-color: #fff;
}
.Notifications_titlesContainer__1qwpH .Notifications_markAsAllRead__2PxTj.Notifications_disabled__2dkf_ {
  opacity: 0.3 !important;
  cursor: default !important;
}

.Notifications_dropdownRoot__3Jec7 {
  width: 100%;
  margin-bottom: 32px;
  border: 1px solid #6b75ff;
  padding: 8px 12px;
  height: 51px;
  border-radius: 4px;
  background-color: #fff;
}
.Notifications_dropdownRoot__3Jec7.Notifications_mb0__3PqZj {
  margin-bottom: 0 !important;
}

.Notifications_dropdownPopover__11QA8 {
  width: 570px;
  height: 250px;
  padding: 24px 32px;
  margin-top: 25px;
}

.Notifications_dropdownCurrencyPopover__1pT9p {
  width: 570px;
  height: 250px;
  padding: 20px 10px;
  margin-top: 40px;
}

.Notifications_item__3sulf {
  height: 45px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid #8ae560;
  width: 100%;
  padding: 0 10px;
  transition: all 0.2s ease;
}
.Notifications_item__3sulf span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #131926;
}
.Notifications_item__3sulf:hover {
  background-color: #6b75ff;
  border-radius: 3px;
  cursor: pointer;
}
.Notifications_item__3sulf:hover span {
  color: #fff;
  font-weight: 600;
}

.Notifications_selectedItem__1DMzb {
  background-color: #6b75ff !important;
  border-radius: 3px;
}
.Notifications_selectedItem__1DMzb span {
  color: #fff !important;
  font-weight: 600 !important;
}

.Notifications_dropdownInput__yfFP3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Notifications_value__2Lj8E {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
@media (min-width: 767px) {
  .Notifications_value__2Lj8E {
    font-size: calc(0.5361930295vw + 9.8873994638px);
  }
}
@media (min-width: 1140px) {
  .Notifications_value__2Lj8E {
    font-size: 16px;
  }
}

.Notifications_dropdownInputContainer__2SXPy {
  border: none !important;
}

.Notifications_dropdownPlaceholder__2RGl6 {
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
@media (min-width: 767px) {
  .Notifications_dropdownPlaceholder__2RGl6 {
    font-size: calc(0.5361930295vw + 9.8873994638px);
  }
}
@media (min-width: 1140px) {
  .Notifications_dropdownPlaceholder__2RGl6 {
    font-size: 16px;
  }
}

.Notifications_tabs__2qwV7 {
  width: 100%;
}

.Notifications_unreadCardContainer__wYui_ {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: #f1f4fc;
  margin: 16px 0 8px 0;
  position: relative;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_unreadBadge__wMLRz {
  background-color: #ff3d71;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 5px;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_cardTitle__3ldUc {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal;
  color: #575fd4;
  margin: 2px 0 !important;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_cardDes__3WcgH {
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: normal !important;
  color: #000;
  margin: 2px 0 11px 0 !important;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_cardFooterContainer__3aA5V {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_cardFooterContainer__3aA5V .Notifications_cardDate__3j3V3 {
  font-size: 12px !important;
  font-weight: normal;
  line-height: normal;
  color: #000;
}
.Notifications_unreadCardContainer__wYui_ .Notifications_cardFooterContainer__3aA5V .Notifications_cardBtn__34gH2 {
  width: 100px;
  height: 30px;
  padding: 3.5px 3px;
  background-color: #6b75ff;
  color: #fff;
  border-radius: 4px;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Notifications_readCardContainer__19OHv {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #bbc2d5;
  margin: 8px 0 8px 0;
  border-radius: 0;
}
.Notifications_readCardContainer__19OHv .Notifications_cardTitle__3ldUc {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal;
  color: #575fd4;
  margin: 2px 0 !important;
}
.Notifications_readCardContainer__19OHv .Notifications_cardDes__3WcgH {
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: normal !important;
  color: #000;
  margin: 2px 0 11px 0 !important;
}
.Notifications_readCardContainer__19OHv .Notifications_cardFooterContainer__3aA5V {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Notifications_readCardContainer__19OHv .Notifications_cardFooterContainer__3aA5V .Notifications_cardDate__3j3V3 {
  font-size: 12px !important;
  font-weight: normal;
  line-height: normal;
  color: #000;
}
.Notifications_readCardContainer__19OHv .Notifications_cardFooterContainer__3aA5V .Notifications_cardBtn__34gH2 {
  width: 100px;
  height: 30px;
  padding: 3.5px 3px;
  background-color: #fff;
  border: 1px solid #6b75ff;
  color: #6b75ff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}
.Header_container__21wXn {
  width: 100%;
  height: 48px;
  background-color: #e4e9f2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Header_container__21wXn.Header_sticky__2gbUz {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.Header_tabs__3q4do {
  min-height: unset !important;
  height: 100%;
}
.Header_tabs__3q4do button {
  margin: 0 20px;
  padding: 0 !important;
  min-height: unset !important;
  min-width: unset !important;
  max-width: unset !important;
  height: 100%;
}

.Header_tab__20pTI {
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000 !important;
  text-transform: capitalize !important;
}

.Header_selectedTab__1fkUS {
  font-weight: 600 !important;
}

.Header_tabFlexContainer__5-Q1a {
  height: 100%;
}

.Header_tabIndicator__1tsQO {
  height: 4px !important;
  padding: 0 10px;
  margin-left: -10px;
  box-sizing: content-box;
}

.Header_selectedSecondTabIndicator__3abX7 {
  left: 197.984px !important;
  width: 142.062px !important;
}

.Header_selectedFirstTabIndicator__1EFLu {
  left: 20px !important;
  width: 142.062px !important;
}

.Header_selectedSubscriptionTabIndicator__cm2tE {
  left: 20px !important;
  width: 173.406px !important;
}

.Header_selectedInvoicesTabIndicator__2fSDD {
  left: 226.75px !important;
  width: 62.7812px !important;
}

.Header_backButton__nCjTu {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  left: 32px;
}
.Header_backButton__nCjTu span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
}
@media (min-width: 767px) {
  .Header_backButton__nCjTu span {
    font-size: calc(0.5361930295vw + 9.8873994638px);
  }
}
@media (min-width: 1140px) {
  .Header_backButton__nCjTu span {
    font-size: 16px;
  }
}
.Header_backButton__nCjTu div {
  margin-right: 3px;
  font-size: 12px;
  font-weight: normal;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}
@media (min-width: 576px) {
  .Header_backButton__nCjTu div {
    margin-right: calc(0.2314814815vw + 1.6666666667px);
  }
}
@media (min-width: 1440px) {
  .Header_backButton__nCjTu div {
    margin-right: 5px;
  }
}
@media (min-width: 767px) {
  .Header_backButton__nCjTu div {
    font-size: calc(0.5361930295vw + 7.8873994638px);
  }
}
@media (min-width: 1140px) {
  .Header_backButton__nCjTu div {
    font-size: 14px;
  }
}
.Header_backButton__nCjTu:hover div {
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px);
}
.HeaderBackButton_container__1Q9v1 {
  height: 36px;
  width: 100%;
  background-color: #e4e9f2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 8;
}
@media (min-width: 576px) {
  .HeaderBackButton_container__1Q9v1 {
    height: calc(1.8518518519vw + 25.3333333333px);
  }
}
@media (min-width: 1440px) {
  .HeaderBackButton_container__1Q9v1 {
    height: 52px;
  }
}
.HeaderBackButton_container__1Q9v1.HeaderBackButton_sticky__2AT67 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.HeaderBackButton_backButton__2eUCi {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  left: 32px;
}
.HeaderBackButton_backButton__2eUCi span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
}
@media (min-width: 767px) {
  .HeaderBackButton_backButton__2eUCi span {
    font-size: calc(0.5361930295vw + 9.8873994638px);
  }
}
@media (min-width: 1140px) {
  .HeaderBackButton_backButton__2eUCi span {
    font-size: 16px;
  }
}
.HeaderBackButton_backButton__2eUCi div {
  margin-right: 3px;
  font-size: 12px;
  font-weight: normal;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}
@media (min-width: 576px) {
  .HeaderBackButton_backButton__2eUCi div {
    margin-right: calc(0.2314814815vw + 1.6666666667px);
  }
}
@media (min-width: 1440px) {
  .HeaderBackButton_backButton__2eUCi div {
    margin-right: 5px;
  }
}
@media (min-width: 767px) {
  .HeaderBackButton_backButton__2eUCi div {
    font-size: calc(0.5361930295vw + 7.8873994638px);
  }
}
@media (min-width: 1140px) {
  .HeaderBackButton_backButton__2eUCi div {
    font-size: 14px;
  }
}
.HeaderBackButton_backButton__2eUCi:hover div {
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px);
}

body {
  padding: 0;
  margin: 0;
}

.InitialLoading_container__3FEYR {
  width: 100%;
  min-height: 100vh;
  background-color: #6b75ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InitialLoading_loadingContainer__1GboL {
  width: 30%;
  min-width: 17em;
  margin-top: 3em;
}

.InitialLoading_loadingBarColorPrimary__10OXg {
  background-color: #8ae560 !important;
}

.InitialLoading_loadingColorPrimary__22DOQ {
  background-color: white !important;
}
.PlanRenewal_wrapper__HWAUO {
  display: flex;
  flex-direction: column;
  padding: 32px 100px;
  position: relative;
}

.PlanRenewal_head__2yvjP {
  margin-bottom: 24px;
}

.PlanRenewal_title__bTDSk {
  line-height: 41px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
  margin-top: 0;
}

.PlanRenewal_inputContainer__t_H_4 {
  margin-bottom: 7px;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_label__1N367 {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
  min-height: 19px;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_inputWrapper__2LY6F {
  position: relative;
  width: 100%;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_inputWrapper__2LY6F > svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 12px;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_input__1X2mr {
  width: 100% !important;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_input__1X2mr input {
  padding: 14.5px 14px !important;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_input__1X2mr fieldset {
  border-color: #d4d8e2;
}
.PlanRenewal_inputContainer__t_H_4 .PlanRenewal_inputError__3_y3s {
  margin-top: 12px;
  color: #ff3d71;
  line-height: 20px;
  height: 40px;
}

.PlanRenewal_boxes__2gsPk {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.PlanRenewal_boxes__2gsPk .PlanRenewal_arrowIcon__28tDY {
  font-size: 30px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: flex;
  align-items: center;
  padding-right: 34px;
  padding-left: 34px;
}

.PlanRenewal_box__z2aiO {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.PlanRenewal_box__z2aiO.PlanRenewal_disable__3zVT2 {
  opacity: 0.5;
}
.PlanRenewal_box__z2aiO .PlanRenewal_title__bTDSk {
  color: #2e3a59;
  margin-bottom: 13px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.PlanRenewal_box__z2aiO .PlanRenewal_inner__qw9Gb {
  padding: 36px 36px 46px;
  border-radius: 4px;
  background-color: #eff1f8;
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: #2e3a59;
}

.PlanRenewal_boxDetails__2IWbw {
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 45px;
  display: block;
}
.PlanRenewal_boxDetails__2IWbw span {
  color: #6b75ff;
}

.PlanRenewal_innerTitle__ZdHeu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.PlanRenewal_innerTitle__ZdHeu .PlanRenewal_title__bTDSk {
  line-height: 33px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.29px;
  color: #2e3a59;
}

.PlanRenewal_radios__OGMEY {
  margin-bottom: 32px;
}

.PlanRenewal_radio__1sRZj {
  margin-top: -3px !important;
}

.PlanRenewal_radioLabelRoot__8ZvCU {
  align-items: flex-start !important;
  margin-bottom: 12px;
}
.PlanRenewal_radioLabelRoot__8ZvCU.PlanRenewal_mb0__3Vx5k {
  margin-bottom: 0;
}
.PlanRenewal_radioLabelRoot__8ZvCU:last-child {
  margin-bottom: 0 !important;
}

.PlanRenewal_radioLabel__1_Ygk {
  line-height: 19px !important;
  font-size: 14px !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
}

.PlanRenewal_radioName__2MeSM {
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: #2e3a59;
}

.PlanRenewal_isDisable__3x5_k {
  opacity: 0.65;
  position: relative;
}
.PlanRenewal_isDisable__3x5_k::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.PlanRenewal_radioDesc__26jL2 {
  line-height: 17px;
  font-size: 12px;
  letter-spacing: -0.14px;
  color: #2e3a59;
}

.PlanRenewal_cardWrapper__2oFS5 {
  padding-bottom: 38px;
  border-bottom: 1px solid #6b75ff;
  margin-bottom: 24px;
  width: 100%;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_label__1N367 {
  display: block;
  margin-bottom: 4px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  color: #6b75ff;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_inputWrapper__2LY6F {
  position: relative;
  width: 100%;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_inputWrapper__2LY6F > svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 12px;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_input__1X2mr {
  width: 100% !important;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_input__1X2mr input {
  padding: 14.5px 14px !important;
}
.PlanRenewal_cardWrapper__2oFS5 .PlanRenewal_input__1X2mr fieldset {
  border-color: #d4d8e2;
}

.PlanRenewal_submit__1mAsB {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 24px;
  margin: 0;
  border-top: 1px solid #6b75ff;
  position: relative;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .PlanRenewal_submit__1mAsB {
    flex-direction: column;
    align-items: flex-start;
  }
}
.PlanRenewal_submit__1mAsB .PlanRenewal_calcBox__Hg6IA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_total__1Esca {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #2e3a59;
  margin: 0;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_total__1Esca .PlanRenewal_num__152mj {
  font-weight: bold;
  margin: 0 12px;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_total__1Esca .PlanRenewal_type__1BPHd {
  font-size: 16px;
  letter-spacing: -0.17px;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_unitAmount__6WNMq {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #2e3a59;
  margin: 0;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_unitAmount__6WNMq .PlanRenewal_num__152mj {
  font-size: 14px;
  font-weight: bold;
  margin: 0 12px;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_unitAmount__6WNMq .PlanRenewal_type__1BPHd {
  font-size: 14px;
  letter-spacing: -0.17px;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_calc__3hvae {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 0;
  margin-top: -6px;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_btn__2Mhb8 {
  min-width: 167px !important;
  height: 55px !important;
}
.PlanRenewal_submit__1mAsB .PlanRenewal_btnLabel__3ecmz {
  font-size: 20px !important;
  line-height: 27px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.PlanRenewal_calcBox__Hg6IA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 30px;
}

.PlanRenewal_total__1Esca {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #2e3a59;
  margin: 0;
}
.PlanRenewal_total__1Esca .PlanRenewal_num__152mj {
  font-size: 17px;
  font-weight: bold;
  margin: 0 12px;
}
.PlanRenewal_total__1Esca .PlanRenewal_type__1BPHd {
  font-size: 16px;
  letter-spacing: -0.17px;
}

.PlanRenewal_unitAmount__6WNMq {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #2e3a59;
  margin: 0;
}
.PlanRenewal_unitAmount__6WNMq .PlanRenewal_num__152mj {
  font-size: 17px;
  font-weight: bold;
  margin: 0 12px;
}
.PlanRenewal_unitAmount__6WNMq .PlanRenewal_type__1BPHd {
  font-size: 14px;
  letter-spacing: -0.17px;
}

.PlanRenewal_calc__3hvae {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.18px;
  color: #2e3a59;
  margin-bottom: 0;
  margin-top: -6px;
}

.PlanRenewal_paper__MrgCt {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;
}

.PlanRenewal_modalWarning__hMt5t {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  max-height: 35vh;
}
.PlanRenewal_modalWarning__hMt5t .PlanRenewal_text__2u_6w {
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.18px;
  margin: 0 0 45px 0;
}
.PlanRenewal_modalWarning__hMt5t .PlanRenewal_text__2u_6w strong {
  margin: 0 10px;
}
.PlanRenewal_modalWarning__hMt5t .PlanRenewal_btnsModal__1tS9Z {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.PlanRenewal_modalWarning__hMt5t .PlanRenewal_btnsModal__1tS9Z .PlanRenewal_cancelBtn__20Cwi {
  text-decoration: underline;
  color: #aaaeb8;
}
.PlanRenewal_modalWarning__hMt5t .PlanRenewal_btnsModal__1tS9Z .PlanRenewal_submitBtnModal__27Dhg {
  width: 30%;
  margin: 0 12px;
}

.PlanRenewal_loading__2Y5SH {
  height: 100%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100;
}

.PlanRenewal_errorText__2JH7G {
  color: #ff3d71;
  font-size: 18px;
  line-height: 1.75rem;
}
.ReadOnly_readOnly__GI0Wi {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3d71;
  padding: 6px;
  color: #fff;
  width: 100%;
  margin: 0 0 10px 0;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_textsContainer__1pzy6 {
  display: flex;
  width: 57%;
  align-items: center;
  justify-content: center;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_texts__9gyYX {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_texts__9gyYX span {
  font-weight: bold;
  font-size: 16px;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_alertIcon__2rGTH {
  margin-right: 25px;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_alertIcon__2rGTH div {
  font-size: 40px;
}
.ReadOnly_readOnly__GI0Wi .ReadOnly_alertIcon__2rGTH div::before {
  color: #fff;
}

.ReadOnly_readOnlyHidden__27Dxk {
  display: none;
}

.ReadOnly_btn__16Enu {
  min-width: 15% !important;
  height: 40px !important;
  margin-left: 14px !important;
}

.ReadOnly_btnLabel__158WX {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
}

.ReadOnly_paper__3yiG4 {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;
  border-radius: 4px !important;
  max-width: 960px !important;
}

.ReadOnly_modal__1oRpi {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3d71;
  padding: 16px 6px;
  color: #fff;
  width: 100%;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG .SyncStatusBar_syncStatusContainer__14VA8 {
  display: flex;
  width: 67%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  min-width: 980px;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG .SyncStatusBar_syncStatusContainer__14VA8.SyncStatusBar_narrowWidth__3XjL4 {
  width: 70%;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG .SyncStatusBar_textsContainer__2S9Jq {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG .SyncStatusBar_title__23Ou_ {
  font-size: 17px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  color: #fff;
}
.SyncStatusBar_failedSyncStatusBar__uDSXG .SyncStatusBar_des__30AZI {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: normal !important;
  color: #fff;
  margin: 0 !important;
}

.SyncStatusBar_warningStatusBar__34jD7 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffaa01;
  padding: 16px 6px;
  color: #fff;
  width: 100%;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_syncStatusContainer__14VA8 {
  display: flex;
  width: 67%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  min-width: 980px;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_syncStatusContainer__14VA8.SyncStatusBar_narrowWidth__3XjL4 {
  width: 70%;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_hideWarningStatusBar__3GCmh {
  display: none;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_textsContainer__2S9Jq {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_title__23Ou_ {
  font-size: 17px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  color: #fff;
}
.SyncStatusBar_warningStatusBar__34jD7 .SyncStatusBar_des__30AZI {
  font-size: 16px !important;
  line-height: normal !important;
  font-weight: normal !important;
  color: #fff;
  margin: 0 !important;
}

.SyncStatusBar_syncStatusBarHidden__2_sy_ {
  display: none;
}

.SyncStatusBar_btn__Lxj61 {
  min-width: 15% !important;
  background: transparent;
  text-decoration: underline;
  height: 40px !important;
  margin-left: 14px !important;
}

.SyncStatusBar_btnLabel__6eE40 {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: normal !important;
  letter-spacing: -0.24px !important;
  background-color: transparent;
  text-decoration: underline;
  color: #fff;
}

.SyncStatusBar_reviewBtnsContainer__1zbms {
  display: flex;
  align-items: center;
}

.SyncStatusBar_reviewUpdatesBtn__1GUfe {
  min-width: 15% !important;
  background-color: #fff !important;
  height: 40px !important;
  margin-left: 10px !important;
  border-radius: 4px;
}

.SyncStatusBar_reviewUpdatesBtnLabel__1gpIn {
  font-size: 16px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  letter-spacing: -0.24px !important;
  color: #ffaa01;
}
.Empty_container__25TbA {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Empty_title__2_tBo {
  margin-top: 0;
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: normal;
  text-align: center;
  color: #bbc2d5;
}

.Empty_image__3vfy2 {
  width: 255px;
  height: 205px;
  object-fit: contain;
}
.NotFound_wrapper__10QzQ {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
}
.Forbidden_container__12b4q {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Forbidden_title__272kX {
  margin-top: 0;
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: normal;
  text-align: center;
  color: #bbc2d5;
}

.Forbidden_image__10nDh {
  width: 255px;
  height: 205px;
  object-fit: contain;
}
.Forbidden_wrapper__y77x7 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
}
.Terms_wrapper__1h7z- {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Terms_container__3pgc- {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Terms_title__1XpgD {
  line-height: 43px;
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.Terms_description__3z5ww {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #000;
}
.CookiePolicy_wrapper__23Vr8 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CookiePolicy_container__2G7Pb {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CookiePolicy_title__DnPEv {
  line-height: 43px;
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.CookiePolicy_description__y2ZCo {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #000;
}
.PrivacyPolicy_wrapper__Q5Shh {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PrivacyPolicy_container__24DTP {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PrivacyPolicy_title__2ULiU {
  line-height: 43px;
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.PrivacyPolicy_description__3ba87 {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #000;
}
* {
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff !important;
}

a {
  font-size: 16px;
  color: #575fd4;
  text-decoration: none;
}

button {
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eff1f8;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575fd4;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #575fd4;
}

.BrainhubCarousel__dots {
  align-items: center;
}

.BrainhubCarousel__dot {
  background-color: transparent;
}

.BrainhubCarousel__dot::before {
  background-color: #8ae560 !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
  border-radius: 100% !important;
  transition: 0.1s;
}

.BrainhubCarousel__dot--selected::before {
  background-color: #6b75ff !important;
  width: 15px !important;
  height: 15px !important;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}
.infinite-scroll-component__outerdiv ::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.MuiInput-underline::before {
  border-bottom-style: solid !important;
  border-bottom-color: #c8d5dc !important;
}

.MuiInput-root.Mui-disabled {
  color: #c8d5dc !important;
}

.MuiRadio-root.MuiIconButton-colorPrimary {
  color: #6b75ff;
}
.MuiRadio-root.Mui-checked span div svg:last-child {
  border: 6px solid #6b75ff;
  border-radius: 50px;
}
.MuiRadio-root.Mui-checked span div svg:last-child path {
  display: none;
}
.MuiRadio-root .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.DayPicker-wrapper * {
  outline: 0;
}

.DayPicker-wrapper {
  outline: 0;
  color: #000;
}
.DayPicker-wrapper .DayPicker-NavBar {
  position: absolute;
  width: 100%;
  height: 24px;
  top: 14px;
}
.DayPicker-wrapper .DayPicker-NavButton {
  width: 1.5em;
  height: 1.5em;
  background-size: unset;
}
.DayPicker-wrapper .DayPicker-NavButton--prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M9.317 12l5.442 6.35a1 1 0 1 1-1.518 1.3l-6-7a1 1 0 0 1 0-1.3l6-7a1 1 0 0 1 1.518 1.3L9.317 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 16px;
  margin: 0;
}
.DayPicker-wrapper .DayPicker-NavButton--next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M14.683 12L9.24 5.65a1 1 0 1 1 1.518-1.3l6 7a1 1 0 0 1 0 1.3l-6 7a1 1 0 1 1-1.518-1.3L14.683 12z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill-rule='nonzero' d='M0 0h24v24H0z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%236B75FF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg fill='%236B75FF' mask='url(%23b)'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  right: 16px;
  margin: 0;
}
.DayPicker-wrapper .DayPicker-Caption > div {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-Months {
  flex-wrap: nowrap;
}
.DayPicker-wrapper .DayPicker-Weekday {
  color: #bbc2d5;
  line-height: 17px;
  font-size: 12px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-WeekdaysRow .DayPicker-Weekday {
  padding: 0 0 7px 0;
}
.DayPicker-wrapper .DayPicker-WeekdaysRow .DayPicker-Weekday abbr {
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DayPicker-wrapper .DayPicker-Day {
  padding: 11px 12.5px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 600;
}
.DayPicker-wrapper .DayPicker-Day--start {
  background-color: #6b75ff !important;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--end {
  background-color: #6b75ff !important;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--outside {
  color: #bbc2d5;
}
.DayPicker-wrapper .DayPicker-TodayButton {
  color: #6b75ff;
}
.DayPicker-wrapper .DayPicker-Day--today {
  color: #8ae560;
}
.DayPicker-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #9ea5ff;
}
.DayPicker-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #6b75ff;
}
.DayPicker-wrapper .DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-wrapper .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #ebecff !important;
  border-radius: 100px;
}
.DayPicker-wrapper .DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--outside {
  background-color: transparent !important;
}
.DayPicker-wrapper .DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--outside {
  background-color: transparent !important;
}

.snackbar {
  min-width: 440px;
  height: 48px;
  border-radius: 3px;
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.snackbar div {
  height: 100% !important;
}
.snackbar .MuiSnackbarContent-message span {
  font-size: 15px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.snackbar .MuiSnackbarContent-message span svg {
  display: none;
}
.snackbar .MuiSnackbarContent-message span::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
  font-family: "icomoon" !important;
  font-size: 15px;
  text-align: center;
  width: 24px;
  margin-right: 12px;
}

.snackbar-success {
  background-color: #2e3a59 !important;
}
.snackbar-success .MuiSnackbarContent-message span::before {
  content: "\e914";
  color: #8ae560;
}
.snackbar-success .MuiSnackbarContent-action span {
  text-transform: uppercase !important;
  color: #8ae560 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.snackbar-error {
  background-color: #ff3d71 !important;
}
.snackbar-error .MuiSnackbarContent-message span::before {
  content: "\e901";
  color: #fff;
  font-size: 20px;
}

@font-face {
  font-family: icomoon;
  src: url(/static/media/icomoon.c9258d5e.eot);
  src: url(/static/media/icomoon.c9258d5e.eot) format("embedded-opentype"), url(/static/media/icomoon.08c3ce67.ttf) format("truetype"), url(/static/media/icomoon.fbcebf3e.woff) format("woff"), url(/static/media/icomoon.fe11481f.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword  */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: graynormalize;
}

.icon-Alert-Filled::before {
  content: "\e900";
  color: #6b75ff;
}

.icon-Alert-Outlined::before {
  content: "\e901";
  color: #6b75ff;
}

.icon-Attachment::before {
  content: "\e902";
  color: #6b75ff;
}

.icon-back::before {
  content: "\e903";
  color: #6b75ff;
}

.icon-Bell-Filled::before {
  content: "\e904";
  color: #6b75ff;
}

.icon-Bell-Outlined::before {
  content: "\e905";
  color: #6b75ff;
}

.icon-Bookmark-Filled::before {
  content: "\e906";
  color: #6b75ff;
}

.icon-Bookmark-Outlined::before {
  content: "\e907";
  color: #6b75ff;
}

.icon-Calendar-Filled::before {
  content: "\e908";
  color: #6b75ff;
}

.icon-Calendar-Outlined::before {
  content: "\e909";
  color: #6b75ff;
}

.icon-Camera-Filled::before {
  content: "\e90a";
  color: #6b75ff;
}

.icon-Camera-Outlined::before {
  content: "\e90b";
  color: #6b75ff;
}

.icon-Cap-Down::before {
  content: "\e90c";
  color: #6b75ff;
}

.icon-Cap-Up::before {
  content: "\e90d";
  color: #6b75ff;
}

.icon-Card-Filled::before {
  content: "\e90e";
  color: #6b75ff;
}

.icon-Card-Outlined::before {
  content: "\e90f";
  color: #6b75ff;
}

.icon-Cart-Filled::before {
  content: "\e910";
  color: #6b75ff;
}

.icon-Cart-Outlined::before {
  content: "\e911";
  color: #6b75ff;
}

.icon-Chart::before {
  content: "\e912";
  color: #6b75ff;
}

.icon-Check-x2::before {
  content: "\e913";
  color: #6b75ff;
}

.icon-Check::before {
  content: "\e914";
  color: #6b75ff;
}

.icon-Chevron-Down::before {
  content: "\e915";
  color: #6b75ff;
}

.icon-Chevron-Left::before {
  content: "\e916";
  color: #6b75ff;
}

.icon-Chevron-Right::before {
  content: "\e917";
  color: #6b75ff;
}

.icon-Chevron-Up::before {
  content: "\e918";
  color: #6b75ff;
}

.icon-Clock-Filled::before {
  content: "\e919";
  color: #6b75ff;
}

.icon-Clock-Outlined::before {
  content: "\e91a";
  color: #6b75ff;
}

.icon-Close::before {
  content: "\e91b";
  color: #6b75ff;
}

.icon-Download::before {
  content: "\e91c";
  color: #6b75ff;
}

.icon-Edit-Filled::before {
  content: "\e91d";
  color: #6b75ff;
}

.icon-Edit-Outlined::before {
  content: "\e91e";
  color: #6b75ff;
}

.icon-Embed::before {
  content: "\e91f";
  color: #6b75ff;
}

.icon-Explore-Filled::before {
  content: "\e920";
  color: #6b75ff;
}

.icon-Explore-Outlined::before {
  content: "\e921";
  color: #6b75ff;
}

.icon-External-link::before {
  content: "\e922";
  color: #6b75ff;
}

.icon-Eye-Filled::before {
  content: "\e923";
  color: #6b75ff;
}

.icon-Eye-Outlined::before {
  content: "\e924";
  color: #6b75ff;
}

.icon-File-Filled::before {
  content: "\e925";
  color: #6b75ff;
}

.icon-File-Outlined::before {
  content: "\e926";
  color: #6b75ff;
}

.icon-Heart-Filled::before {
  content: "\e927";
  color: #6b75ff;
}

.icon-Heart-Outlined::before {
  content: "\e928";
  color: #6b75ff;
}

.icon-Home-Filled::before {
  content: "\e929";
  color: #6b75ff;
}

.icon-Home-Outlined::before {
  content: "\e92a";
  color: #6b75ff;
}

.icon-Image-Filled::before {
  content: "\e92b";
  color: #6b75ff;
}

.icon-Image-Outlined::before {
  content: "\e92c";
  color: #6b75ff;
}

.icon-Info-Filled::before {
  content: "\e92d";
  color: #6b75ff;
}

.icon-Info-Outlined::before {
  content: "\e92e";
  color: #6b75ff;
}

.icon-Link::before {
  content: "\e92f";
  color: #6b75ff;
}

.icon-Lock-Filled::before {
  content: "\e930";
  color: #6b75ff;
}

.icon-Lock-Outlined::before {
  content: "\e931";
  color: #6b75ff;
}

.icon-Mail-Filled::before {
  content: "\e932";
  color: #6b75ff;
}

.icon-Mail-Outlined::before {
  content: "\e933";
  color: #6b75ff;
}

.icon-Menu::before {
  content: "\e934";
  color: #6b75ff;
}

.icon-Message-Filled::before {
  content: "\e935";
  color: #6b75ff;
}

.icon-Mic-Filled::before {
  content: "\e936";
  color: #6b75ff;
}

.icon-Mic-Outlined::before {
  content: "\e937";
  color: #6b75ff;
}

.icon-Minus::before {
  content: "\e938";
  color: #6b75ff;
}

.icon-More::before {
  content: "\e939";
  color: #6b75ff;
}

.icon-Paperplane-Filled::before {
  content: "\e93a";
  color: #6b75ff;
}

.icon-Paperplane-Outlined::before {
  content: "\e93b";
  color: #6b75ff;
}

.icon-Pause-Filled::before {
  content: "\e93c";
  color: #6b75ff;
}

.icon-Pause-Outlined::before {
  content: "\e93d";
  color: #6b75ff;
}

.icon-Person-Filled::before {
  content: "\e93e";
  color: #6b75ff;
}

.icon-Person-Outlined::before {
  content: "\e93f";
  color: #6b75ff;
}

.icon-Phone-Filled::before {
  content: "\e940";
  color: #6b75ff;
}

.icon-Phone-Outlined::before {
  content: "\e941";
  color: #6b75ff;
}

.icon-Play-Filled::before {
  content: "\e942";
  color: #6b75ff;
}

.icon-Play-Outlined::before {
  content: "\e943";
  color: #6b75ff;
}

.icon-Plus::before {
  content: "\e944";
  color: #6b75ff;
}

.icon-Refresh::before {
  content: "\e945";
  color: #6b75ff;
}

.icon-Search::before {
  content: "\e946";
  color: #6b75ff;
}

.icon-Settings-Filled::before {
  content: "\e947";
  color: #6b75ff;
}

.icon-Settings-Outlined::before {
  content: "\e948";
  color: #6b75ff;
}

.icon-Share-Filled::before {
  content: "\e949";
  color: #6b75ff;
}

.icon-Share-Outlined::before {
  content: "\e94a";
  color: #6b75ff;
}

.icon-Star-Filled::before {
  content: "\e94b";
  color: #6b75ff;
}

.icon-Star-Outlined::before {
  content: "\e94c";
  color: #6b75ff;
}

.icon-Trash-Filled::before {
  content: "\e94d";
  color: #6b75ff;
}

.icon-Trash-Outlined::before {
  content: "\e94e";
  color: #6b75ff;
}

.icon-Upload::before {
  content: "\e94f";
  color: #6b75ff;
}

.icon-World::before {
  content: "\e950";
  color: #6b75ff;
}

.icon-menu-invoicing::before {
  content: "\e951";
}

.icon-menu-planning::before {
  content: "\e952";
}

.icon-menu-profile::before {
  content: "\e953";
}

.icon-menu-projects::before {
  content: "\e954";
}

.icon-menu-reports::before {
  content: "\e955";
}

.icon-menu-settings::before {
  content: "\e956";
}

.icon-menu-timeApproval::before {
  content: "\e957";
}

.icon-menu-timeEntry::before {
  content: "\e958";
}

.icon-planning-planningAssistant .path1::before {
  content: "\e959";
  color: #8ae560;
}

.icon-planning-planningAssistant .path2::before {
  content: "\e95a";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-planningAssistant .path3::before {
  content: "\e95b";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-resourcePlanning .path1::before {
  content: "\e95c";
  color: #8ae560;
}

.icon-planning-resourcePlanning .path2::before {
  content: "\e95d";
  margin-left: -1em;
  color: #6b75ff;
}

.icon-planning-resourcePlanning .path3::before {
  content: "\e95e";
  margin-left: -1em;
  color: #6b75ff;
}
