@import 'theme/variables/colors';

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  line-height: 43px;
  font-size: 32px;
  font-weight: bold;
  color: $black;
}

.description {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 19px;
  font-size: 14px;
  color: $black;
}
