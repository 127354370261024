@import 'theme/variables/colors';
@import 'theme/utils/functions';
@import 'theme/utils/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 100px;
  position: relative;
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -50px;
  margin-bottom: 20px;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.backBtnLabel {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-dark-color;
}

.head {
  margin-bottom: 24px;
}

.title {
  line-height: 41px;
  font-size: 30px;
  font-weight: bold;
  color: $black;
  margin-bottom: 4px;
  margin-top: 0;
}

.inputContainer {
  margin-bottom: 37px;

  .label {
    display: block;
    margin-bottom: 4px;
    line-height: 19px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.17px;
    color: $primary-color;
    min-height: 19px;
  }

  .inputWrapper {
    position: relative;
    width: 100%;

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }

  .input {
    width: 100% !important;

    input {
      padding: 14.5px 14px !important;
    }

    fieldset {
      border-color: $mischka;
    }
  }

  .inputError {
    margin-top: 12px;
    color: $red;
    line-height: 20px;
    height: 40px;
  }
}

.boxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;

  .arrowIcon {
    font-size: 30px;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    padding-right: 34px;
    padding-left: 34px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.disable {
    opacity: 0.5;
  }

  .title {
    color: $rhino-blue;
    margin-bottom: 13px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .inner {
    padding: 30px 26px;
    border-radius: 4px;
    background-color: $white-lilac;
    line-height: 32px;
    font-size: 16px;
    letter-spacing: -0.19px;
    color: $rhino-blue;
    min-height: 280px;
    height: 270px;
  }
}

.boxDetails {
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.18px;
  color: $rhino-blue;
  margin-bottom: 45px;
  display: block;

  span {
    color: $primary-color;
  }
}

.innerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .title {
    line-height: 33px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.29px;
    color: $rhino-blue;
  }
}

.radios {
  margin-bottom: 32px;
}

.radio {
  margin-top: -3px !important;
}

.radioLabelRoot {
  align-items: flex-start !important;
  margin-bottom: 12px;

  &.mb0 {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.radioLabel {
  line-height: 19px !important;
  font-size: 14px !important;
  color: $black !important;
  display: flex !important;
  flex-direction: column !important;
}

.radioName {
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.19px;
  color: $rhino-blue;
}

.isDisable {
  opacity: 0.65;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
}

.radioDesc {
  line-height: 17px;
  font-size: 12px;
  letter-spacing: -0.14px;
  color: $rhino-blue;
}

.cardWrapper {
  padding-bottom: 38px;
  border-bottom: 1px solid $primary-color;
  margin-bottom: 24px;
  width: 100%;

  .label {
    display: block;
    margin-bottom: 4px;
    line-height: 19px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.17px;
    color: $primary-color;
  }

  .inputWrapper {
    position: relative;
    width: 100%;

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }

  .input {
    width: 100% !important;

    input {
      padding: 14.5px 14px !important;
    }

    fieldset {
      border-color: $mischka;
    }
  }
}

.submit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 24px;
  margin: 0;
  border-top: 1px solid $primary-color;
  position: relative;
  margin-top: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .calcBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .total {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.24px;
    display: flex;
    align-items: center;
    color: $rhino-blue;
    margin: 0;

    .num {
      font-weight: bold;
      margin: 0 12px;
    }

    .type {
      font-size: 14px;
      letter-spacing: -0.17px;
    }
  }

  .calc {
    font-size: 15px;
    line-height: 32px;
    letter-spacing: -0.18px;
    color: $rhino-blue;
    margin-bottom: 0;
    margin-top: -6px;
  }

  .btn {
    min-width: 167px !important;
    height: 55px !important;
  }

  .btnLabel {
    font-size: 20px !important;
    line-height: 27px !important;
    font-weight: bold !important;
    letter-spacing: -0.24px !important;
  }
}

.paper {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;
}

.modalWarning {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  max-height: 35vh;

  .text {
    line-height: 20px;
    font-size: 15px;
    letter-spacing: -0.18px;
    margin: 0 0 45px 0;

    strong {
      margin: 0 10px;
    }
  }

  .btnsModal {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cancelBtn {
      text-decoration: underline;
      color: $aluminium;
    }

    .submitBtnModal {
      width: 30%;
      margin: 0 12px;
    }
  }
}

.loading {
  height: 100%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100;
}

.errorText {
  color: $red;
  font-size: 18px;
  line-height: 1.75rem;
}

.cancel {
  font-size: 14px;
  font-weight: 600;
  width: 104px;
  height: 55px !important;
  margin: 0;
  margin-right: 20px !important;
}

.cancelBtnLabel {
  font-size: 17px !important;
  line-height: 20px !important;
  font-weight: bold !important;
  letter-spacing: -0.24px !important;
  color: $aluminium;
  text-decoration: underline;
}
