@import 'theme/variables/colors';
@import 'theme/utils/mixins';

$drawer-transition-time: 0.5s;
$drawer-small-width: 72px;
$drawer-large-width: 275px;
$drawer-top-padding: 26px;
$drawer-bottom-padding: 16px;
$icon-width: $drawer-small-width;
$icon-height: 80px;
$icon-font-size: 33px;

.container {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $primary-color;
  width: $drawer-small-width;
  z-index: 999;
  padding: $drawer-top-padding 0 $drawer-bottom-padding 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  // border-top-right-radius: $radius-medium;
  // border-bottom-right-radius: $radius-medium;
  transform: translateX(-$drawer-small-width - 6px) scale(1.15);
  transition: width $drawer-transition-time ease-in-out, transform 230ms ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    width: $drawer-large-width;

    .listItemText {
      transform: translate3d(0, 0, 0) !important;
      opacity: 1 !important;
    }

    .logoContainer {
      .logo {
        width: 100%;
        height: 48px;
        border-radius: 48px;

        svg {
          width: 100px;
        }
      }
    }

    .listItem {
      &.active {
        &::before {
          width: 93%;
        }
      }
    }
  }

  &.open {
    transform: translateX(0) scale(1);
  }
}

.logoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px $drawer-top-padding 18px;

  // prettier-ignore
  .logo {
    width: 48px;
    background-color: $link-color;
    border-radius: 48px;
    transition:
      width $drawer-transition-time ease-in-out,
      border-radius $drawer-transition-time ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.listItem {
  padding: 0 !important;
  position: relative !important;
  height: 60px;
  margin: 15px 0 !important;
  transition: background-color 300ms ease-in-out !important;
  background-color: transparent !important;

  &:first-child {
    margin-top: 0 !important;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-top-right-radius: $radius-medium;
    border-bottom-right-radius: $radius-medium;
    transition: width 300ms;
  }

  &:not(.active):hover {
    &::before {
      width: 90%;
      background-color: $link-color !important;
    }
  }

  &.active {
    &::before {
      width: 90%;
      background-color: $secondary-color;
    }

    .listItemText {
      color: $link-color;
    }
  }
}

.listItemText {
  min-width: 125px !important;
  overflow: hidden;

  span {
    font-size: 17px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
  }

  opacity: 0;
  transform: translate3d(-25px, 0, 0) !important;
  transition: all 300ms linear;
}

.icon {
  width: $icon-width;
  height: $icon-height;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  div {
    object-fit: contain;
    color: $white;
    font-size: $icon-font-size;
  }
}

.userItems {
  margin-top: auto !important;
}

.mini {
  &.container {
    height: 96vh;
    top: 2vh;
    padding: 16px 0 0 0;
  }

  .logoContainer {
    padding: 0 18px 8px 18px;
  }

  .listItem {
    height: 54px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}

.paper {
  width: auto;
  min-height: 250px;
  height: auto;
  overflow: hidden;

  border-radius: 4px !important;

  max-width: 960px !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
